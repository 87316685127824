import React, { useMemo, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { CloseIcon } from "components/Button/index";
import { lightBlurBg } from "utils/helper-ts";
import { AppStateContext } from "context";
import { AsyncStatus, type IVariant } from "types";

interface IFooterItem {
  label: string;
  action: () => void;
  buttonType?: "primary" | "secondary";
  className?: string;
}

export const Modal = ({
  icon,
  rightIcon,
  title,
  visible,
  size = "custom",
  imageSrc,
  imageDescription,
  onOk,
  onCancel,
  children,
  customFooter,
  titleClassName,
  titleContainerClassName,
  imageClassName,
  imageDescClassName,
  showCancel,
  showCloseIcon = true,
  backgroundColor = "bg-widget",
  showBackground = false,
  showSideBar = true,
  loading,
}: {
  title: React.ReactNode;
  visible: boolean;
  onCancel?: () => void;
  children: React.ReactNode;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  size?: "small" | "medium" | "large" | "custom" | string;
  imageSrc?: string;
  imageDescription?: string;
  onOk?: () => void;
  titleClassName?: string;
  titleContainerClassName?: string;
  customFooter?: Array<IFooterItem>;
  imageClassName?: string;
  imageDescClassName?: string;
  showCancel?: boolean;
  showCloseIcon?: boolean;
  backgroundColor?: string;
  showBackground?: boolean;
  showSideBar?: boolean;
  loading?: AsyncStatus;
}) => {
  const { collapseSidebar } = useContext(AppStateContext);
  const modalSize = useMemo(() => {
    switch (size) {
      case "small":
        return "w-[500px]";
      case "medium":
        return "w-[625px]";
      case "large":
        return "w-[768px]";
      case "full":
        return collapseSidebar ? "w-11/12" : "w-10/12";
      case "custom":
      default:
        return size;
    }
  }, [size, collapseSidebar]);

  if (!visible) {
    return null;
  }

  // full page modals like the detail modals: category, product, variant
  // maybe this can be smoother, almost want to make separate modals, but we'll see
  if (!showSideBar) {
    return ReactDOM.createPortal(
      <DetailModalWrapper
        title={title}
        icon={icon}
        rightIcon={rightIcon}
        onCancel={onCancel}
        titleClassName={titleClassName}
        titleContainerClassName={titleContainerClassName}
      >
        {children}
      </DetailModalWrapper>,
      document.getElementById("modal-root") as Element
    );
  }

  return ReactDOM.createPortal(
    <div
      tabIndex={-1}
      aria-hidden="true"
      className={`overflow-y-auto overflow-hidden fixed z-[100] flex items-center justify-center text-base-text inset-0 w-full h-full ${
        collapseSidebar ? "ml-[35px]" : "ml-[120px]"
      }`}
    >
      <div
        style={lightBlurBg}
        className={`w-full h-full absolute ${collapseSidebar ? "ml-[70px]" : "ml-[240px]"}`}
        onClick={onCancel}
      ></div>
      <div className={`${modalSize} ${backgroundColor} rounded-xl relative z-[1001] shadow-xl`}>
        <div className="relative rounded-xl flex flex-col justify-center border border-border-internal overflow-hidden">
          <ModalHeader
            titleContainerClassName={titleContainerClassName || ""}
            icon={icon}
            titleClassName={titleClassName || ""}
            title={title}
            rightIcon={rightIcon}
            onCancel={onCancel}
            showCloseIcon={showCloseIcon}
          />
          <ModalScrollWrapper>{children}</ModalScrollWrapper>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") as Element
  );
};

export default Modal;

const ModalScrollWrapper = ({ children }: { children: React.ReactNode }) => {
  return <section className="overflow-auto max-h-[calc(100vh-120px)]">{children}</section>;
};

const DetailModalWrapper = ({
  title,
  icon,
  rightIcon,
  onCancel,
  children,
  titleClassName,
  titleContainerClassName,
  showCloseIcon = true,
  backgroundColor = "bg-widget",
  loading,
}: {
  title: React.ReactNode;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onCancel?: () => void;
  children: React.ReactNode;
  titleClassName?: string;
  titleContainerClassName?: string;
  showCloseIcon?: boolean;
  backgroundColor?: string;
  loading?: AsyncStatus;
}) => {
  return (
    <div
      tabIndex={-1}
      aria-hidden="true"
      className={`overflow-y-auto overflow-hidden fixed z-[1001] flex items-center justify-center text-base-text inset-0 w-full h-full`}
    >
      <div
        style={lightBlurBg}
        className={`w-full h-full absolute bg-black/10`}
        onClick={onCancel}
      ></div>
      <div className={`w-11/12 ${backgroundColor} rounded-xl relative z-[1001] shadow-xl`}>
        <div className="relative rounded-xl flex flex-col justify-center border border-border-internal overflow-hidden">
          <ModalHeader
            titleContainerClassName={titleContainerClassName || ""}
            icon={icon}
            titleClassName={titleClassName || ""}
            title={title}
            rightIcon={rightIcon}
            onCancel={onCancel}
            showCloseIcon={showCloseIcon}
          />
          <ModalScrollWrapper>{children}</ModalScrollWrapper>
        </div>
      </div>
    </div>
  );
};

const ModalHeader = ({
  titleContainerClassName,
  icon,
  titleClassName,
  title,
  rightIcon,
  onCancel,
  showCloseIcon,
}: {
  titleContainerClassName: string;
  icon: React.ReactNode;
  titleClassName: string;
  title: React.ReactNode;
  rightIcon: React.ReactNode;
  onCancel?: () => void;
  showCloseIcon: boolean;
}) => {
  return (
    <header className="flex justify-between items-center px-8 py-3 rounded-t border-b">
      <div className={`flex gap-3 justify-center items-center ${titleContainerClassName}`}>
        {icon ? icon : null}
        <h3 className={`font-bold my-0 ${titleClassName}`}>{title}</h3>
      </div>
      <div className="flex gap-3 justify-center items-center">
        {rightIcon && rightIcon}
        {onCancel && showCloseIcon && <CloseIcon onClick={onCancel} />}
      </div>
    </header>
  );
};

export const ModalLegacyFooter = ({
  onCancel,
  showCancel,
  onOk,
  customFooter,
}: {
  onCancel?: () => void;
  showCancel?: boolean;
  onOk?: () => void;
  customFooter?: Array<IFooterItem>;
}) => {
  if ((onCancel && showCancel) || onOk) {
    return (
      <footer className="flex items-center justify-center pt-2 pb-10 px-8 space-x-2.5 rounded-b">
        {customFooter ? (
          customFooter.map((item, idx) => (
            <button onClick={item.action} className={item.className} key={idx}>
              {item.label}
            </button>
          ))
        ) : (
          <>
            {onCancel && showCancel && (
              <button
                onClick={onCancel}
                className="rounded-md border border-base-text px-5 py-1.5 bg-white text-base-text text-sm hover:bg-gray-200"
              >
                Cancel
              </button>
            )}
            {onOk && (
              <button
                onClick={onOk}
                className="rounded-md border-base-text px-5 py-1.5 bg-base-text text-widget text-sm hover:bg-black"
              >
                Ok
              </button>
            )}
          </>
        )}
      </footer>
    );
  } else {
    return null;
  }
};

// may not be able to effectively generalize in this pr
export const ModalLegacySection = ({ children }: { children: React.ReactNode }) => {
  return (
    <section className="flex flex-col items-center pt-4 pb-4 px-8 overflow-auto max-h-[calc(100vh-200px)]">
      {/* this is where the image and image description previously went */}
      {children}
    </section>
  );
};

export const ModalLegacyImage = ({
  imageSrc,
  imageClassName,
  imageDescription,
}: {
  imageSrc: string;
  imageClassName?: string;
  imageDescription?: string;
}) => {
  return (
    <img
      src={imageSrc}
      alt=""
      className={`${imageClassName} ${imageDescription ? "pb-2.5" : "pb-7"}`}
    />
  );
};

export const ModalLegacyImageDescription = ({
  imageDescClassName,
  imageDescription,
}: {
  imageDescClassName: string;
  imageDescription?: string;
}) => {
  return (
    <p className={imageDescClassName ? imageDescClassName : `pb-7 text-sm`}>{imageDescription}</p>
  );
};
