import { PillCallout } from "components/Pill";
import { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AppStateContext,
  DemandContext,
  InfoModalContext,
  InventoryContext,
  ModalContext,
  PlanningContext,
  ProductContext,
} from "context";
import client from "api";
import Dropdown, { POSITION } from "components/Dropdown";
import VariantsDropdown from "components/CustomDropdowns/VariantsDropdown";
import {
  FiltersDropdown,
  MultiselectDropdown,
  NumericRangeDropdown,
  SingleSelectDropdown,
  RadioDropdown,
  TaggingDropdown,
} from "components/CustomDropdowns/Filters";
import useDebounce from "hooks/useDebounce";
import { FILTER_PLACEHOLDERS } from "utils/constants";

import { ReactComponent as Clear } from "assets/images/clear.svg";
import { ReactComponent as Rect } from "assets/images/rect.svg";
import { ReactComponent as Grid } from "assets/images/grid.svg";
import { ReactComponent as Variant } from "assets/images/variant.svg";
import { ReactComponent as Dollar } from "assets/images/dollar.svg";
import { ReactComponent as Sales } from "assets/images/sales.svg";
import { ReactComponent as Truck } from "assets/images/truck.svg";
import { ReactComponent as Brand } from "assets/images/brand.svg";
import { ReactComponent as Image } from "assets/images/image.svg";
import { ReactComponent as Subject } from "assets/images/subject.svg";
import { ReactComponent as Barbell } from "assets/images/barbell.svg";
import { ReactComponent as InventoryStatus } from "assets/images/inventory_status.svg";
import { ReactComponent as Shop } from "assets/images/shop-w.svg";
import { ReactComponent as Calendar } from "assets/images/calendar-w.svg";
import { ReactComponent as Champion } from "assets/images/champion.svg";
import { ReactComponent as Growth } from "assets/images/growth.svg";

import type { ReactNode } from "react";
import {
  type IObject,
  type IProduct,
  type IVariant,
  type IVariantWrapper,
  type IFilterPlus,
  type FilterKey,
  type PagesWithFilters,
  type DropdownItem,
  AsyncStatus,
} from "types";
import SearchAndSelectDropdown from "components/CustomDropdowns/Filters/SearchAndSelect";
import Loading from "components/Loading";
import { formatFilter, saveState } from "utils/helper-ts";

const FiltersWithoutDropdown: FilterKey[] = ["topPerformers", "recommendedPurchases"];

const truncateClass = "whitespace-nowrap text-ellipsis overflow-hidden max-w-[120px]";

const iconMap = {
  category: <Rect className="h-4 w-4" />,
  sku: <Variant className="h-4 w-4" />,
  product: <Grid className="h-4 w-4" />,
  variant: <Variant className="h-4 w-4" />,
  price: <Dollar className="h-4 w-4" />,
  cost: <Dollar className="h-4 w-4" />,
  sales: <Sales className="h-4 w-4" />,
  hasSales: <Sales className="h-4 w-4" />,
  sale: <Sales className="h-4 w-4" />,
  noSale: <Sales className="h-4 w-4" />,
  vendor: <Truck className="h-4 w-4" />,
  tag: <Brand className="h-4 w-4" />,
  hasImage: <Image className="h-4 w-4" />,
  hasDescription: <Subject className="h-4 w-4" />,
  weight: <Barbell className="h-4 w-4" />,
  status: <InventoryStatus className="h-4 w-4" />,
  inventoryStatus: <InventoryStatus className="h-4 w-4" />,
  location: <Shop className="h-4 w-4" />,
  unitsOnHand: <Shop className="h-4 w-4" />,
  created: <Calendar className="h-4 w-4" />,
  modified: <Calendar className="h-4 w-4" />,
  topPerformers: <Champion className="h-4 w-4" />,
  recommendedPurchases: <Growth className="h-4 w-4" />,
  more: null,
};

const label = (icon: ReactNode, value: string, name: string) => (
  <div className="flex items-center px-2.5 cursor-pointer space-x-2 h-6">
    {icon}
    <span className={truncateClass} title={name || value}>
      {name || value}
    </span>
  </div>
);

const getLabel = (key: FilterKey, value: string, name: string) => {
  return label(iconMap[key], value, name);
};

interface Props {
  onApply: () => void;
  pageName: PagesWithFilters;
  overrideRefFilter?: IFilterPlus[];
  updateOverrideRefFilter?: any;
  disabled?: boolean;
  title?: string;
}

export const Filter = ({
  onApply,
  pageName,
  overrideRefFilter,
  updateOverrideRefFilter,
  disabled,
}: Props) => {
  const [fetchVariants, updateFetchVariants] = useState(false);
  const [fOverrideRefCategories, updateFOverrideRefCategories] = useState<string[]>([]);
  const [fOverrideRefProducts, updateFOverrideRefProducts] = useState<string[]>([]);
  const [fOverrideRefVariants, updateFOverrideRefVariants] = useState<string[]>([]);
  const [fOverrideSKUs, updateFOverrideSKUs] = useState<string[]>([]);
  const [overrideRefLoading, setOverrideRefLoading] = useState(AsyncStatus.Loaded);

  const [searchParams] = useSearchParams();
  const {
    products,
    getProducts,
    categories,
    productsLoading,
    categoriesLoading,
    getCategories,
    SKUs,
    getSKUs,
  } = useContext(AppStateContext);

  const {
    fDemandSuiteProducts,
    updateFDemandSuiteProducts,
    fDemandSuiteSelectedVariants,
    fDemandSuiteSKUs,
    updateFDemandSuiteSKUs,
    updateFDemandSuiteSelectedVariants,
    fDemandSuiteSelectedCategories,
    updateFDemandSuiteSelectedCategories,
    demandSuiteFilter,
    updateDemandSuiteFilter,
    loading: demandLoading,
  } = useContext(DemandContext);

  const {
    fProducts,
    updateFProducts,
    fSelectedVariants,
    updateFSelectedVariants,
    productFilter,
    updateProductFilter,
    loading: productLoading,
  } = useContext(ProductContext);

  const {
    inventoryFilter,
    updateInventoryFilter,
    fInventoryCategories,
    updateFInventoryCategories,
    fInventoryProducts,
    updateFInventoryProducts,
    fInventorySKUs,
    updateFInventorySKUs,
    fInventoryVariants,
    updateFInventoryVariants,
    loading: inventoryLoading,
  } = useContext(InventoryContext);

  const {
    planningFilter,
    updatePlanningFilter,
    fPlanningCategories,
    updateFPlanningCategories,
    fPlanningProducts,
    updateFPlanningProducts,
    fPlanningVariants,
    updateFPlanningVariants,
    loading: planningLoading,
    fPlanningSKUs,
    updateFPlanningSKUs,
  } = useContext(PlanningContext);

  const FilterCategoriesMap: Record<PagesWithFilters, string[]> = {
    products: [],
    demandSuite: fDemandSuiteSelectedCategories,
    inventory: fInventoryCategories,
    planning: fPlanningCategories,
    overrideReference: fOverrideRefCategories,
  };

  const updateFCategoriesMap: Record<PagesWithFilters, (items: any) => void> = {
    products: () => {},
    demandSuite: updateFDemandSuiteSelectedCategories,
    inventory: updateFInventoryCategories,
    planning: updateFPlanningCategories,
    overrideReference: updateFOverrideRefCategories,
  };

  const getFSelectedCategories = () => {
    return FilterCategoriesMap[pageName] || [];
  };

  const getFSelectedSKUs = () => {
    return FilterSKUsMap[pageName] || [];
  };

  const FilterProductsMap: Record<PagesWithFilters, string[]> = {
    products: fProducts,
    demandSuite: fDemandSuiteProducts,
    inventory: fInventoryProducts,
    planning: fPlanningProducts,
    overrideReference: fOverrideRefProducts,
  };

  const updateFProductsMap: Record<PagesWithFilters, (items: any) => void> = {
    products: updateFProducts,
    demandSuite: updateFDemandSuiteProducts,
    inventory: updateFInventoryProducts,
    planning: updateFPlanningProducts,
    overrideReference: updateFOverrideRefProducts,
  };

  const FilterSKUsMap: Record<PagesWithFilters, string[]> = {
    products: [],
    demandSuite: fDemandSuiteSKUs,
    inventory: fInventorySKUs,
    planning: fPlanningSKUs,
    overrideReference: fOverrideSKUs,
  };

  const updateFSKUsMap: Record<PagesWithFilters, (items: any) => void> = {
    products: () => {},
    demandSuite: updateFDemandSuiteSKUs,
    inventory: updateFInventorySKUs,
    planning: updateFPlanningSKUs,
    overrideReference: updateFOverrideSKUs,
  };

  const updateFVariantsMap: Record<PagesWithFilters, (items: any) => void> = {
    products: updateFSelectedVariants,
    demandSuite: updateFDemandSuiteSelectedVariants,
    inventory: updateFInventoryVariants,
    planning: updateFPlanningVariants,
    overrideReference: updateFOverrideRefVariants,
  };

  const FilterVariantsMap: Record<PagesWithFilters, string[]> = {
    products: fSelectedVariants,
    demandSuite: fDemandSuiteSelectedVariants,
    inventory: fInventoryVariants,
    planning: fPlanningVariants,
    overrideReference: fOverrideRefVariants,
  };

  const FiltersMap: Record<PagesWithFilters, IFilterPlus[]> = {
    products: productFilter,
    demandSuite: demandSuiteFilter,
    inventory: inventoryFilter,
    planning: planningFilter,
    overrideReference: overrideRefFilter || [],
  };

  const UpdateFiltersMap: Record<PagesWithFilters, (items: any) => void> = {
    products: updateProductFilter,
    demandSuite: updateDemandSuiteFilter,
    inventory: updateInventoryFilter,
    planning: updatePlanningFilter,
    overrideReference: updateOverrideRefFilter,
  };

  const SaveFiltersMap: Record<PagesWithFilters, string> = {
    overrideReference: "override_reference_filter",
    demandSuite: "demand_filter",
    inventory: "inventory_filter",
    planning: "planning_filter",
    products: "products_filter",
  };

  const LoadingMap: Record<PagesWithFilters, AsyncStatus> = {
    products: productLoading,
    demandSuite: demandLoading,
    inventory: inventoryLoading,
    planning: planningLoading,
    overrideReference: overrideRefLoading,
  };

  const pageFilter = FiltersMap[pageName];
  const updatePageFilter = UpdateFiltersMap[pageName];
  const pageFCategories = FilterCategoriesMap[pageName];
  const pageUpdateFCategories = updateFCategoriesMap[pageName];
  const pageFProducts = FilterProductsMap[pageName];
  const pageUpdateFProducts = updateFProductsMap[pageName];
  const pageFSKUs = FilterSKUsMap[pageName];
  const pageUpdateFSKUs = updateFSKUsMap[pageName];
  const pageFVariants = FilterVariantsMap[pageName];
  const pageUpdateFVariants = updateFVariantsMap[pageName];
  const pageLoading = LoadingMap[pageName];

  //this should be contained in components
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [variants, setVariants] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [variantKeyword, setVariantKeyword] = useState<string>("");
  const [variantsLoading, setVariantsLoading] = useState(AsyncStatus.Loading);

  const getVariants = async (productIds: string[], searchString: string) => {
    try {
      if (localStorage.getItem("shop")) {
        setVariantsLoading(AsyncStatus.Loading);
        const res = await client.post(`/variants`, {
          productId: productIds,
          searchString,
        });
        setVariants(res.data.data);

        if (res.data.data.length > 0) {
          setVariantsLoading(AsyncStatus.Loaded);
        } else {
          setVariantsLoading(AsyncStatus.Empty);
        }

        return res.data.data;
      }
    } catch (err) {
      console.error(err);
      setVariantsLoading(AsyncStatus.Failed);
    }
  };

  useEffect(() => {
    async function productChange() {
      const selectedProducts = pageFProducts;
      if (selectedProducts.length > 0) {
        setVariants([]);
        await getVariants(selectedProducts, "");
      }
    }
    productChange();
  }, [pageName, JSON.stringify(pageFProducts)]);

  /*   useEffect(() => {
    if (fetchVariants) {
      handleSelectedVariants(
        variants
          .map((item: IVariantWrapper) => item.variants)
          .flat()
          .map((variant: IVariant) => variant.id) || []
      );
    } else {
      handleSelectedVariants(pageFVariants);
    }
  }, [fetchVariants, JSON.stringify(variants)]); */

  const addMoreFilter = (
    filterArray: IFilterPlus[],
    updateFunction: (items: IFilterPlus[]) => void
  ) => {
    const idx = filterArray.findIndex((item) => item.key === "more");
    if (idx === -1) {
      updateFunction([
        ...filterArray,
        { key: "more", value: "Filter & Search", name: "Filter & Search" },
      ]);
    }
  };

  useEffect(() => {
    addMoreFilter(pageFilter, updatePageFilter);
  }, [JSON.stringify(pageFilter), updatePageFilter]);

  useEffect(() => {
    if (pageName === "products") {
      const addCost = searchParams.get("addCost");
      if (addCost) {
        updateProductFilter((old: IFilterPlus[]) => [
          ...old,
          { key: "cost", value: "no value", name: "cost: no value" },
        ]);
      }
    }
  }, [pageName, searchParams]);

  const handleSelectedCategories = (ids: string[]) => {
    let categoryText = "No Categories";

    if (ids.length === 0) {
      categoryText = "No Categories";
    } else if (ids.length === 1) {
      categoryText = ids[0];
    } else {
      categoryText = `${ids.length} Categories`;
    }

    pageUpdateFCategories(ids);
    const idx = pageFilter.findIndex((item: IFilterPlus) => item.key === "category");
    if (idx !== -1) {
      updatePageFilter((old: IFilterPlus[]) => {
        return [
          ...old.slice(0, idx),
          { key: "category", value: ids, name: categoryText },
          ...old.slice(idx + 1),
        ];
      });
    }
  };

  const handleSelectedProducts = (ids: string[], pName?: string) => {
    let productText = "No Products";

    if (ids.length === products?.length) {
      productText = "All Products";
    } else if (ids.length === 0) {
      productText = "No Products";
    } else if (ids.length === 1) {
      const item = products?.find((item: DropdownItem) => item.id === ids[0]);
      productText = item?.title || pName || "";
    } else {
      productText = `${ids.length} Products`;
    }

    pageUpdateFProducts(ids);
    const idx = pageFilter.findIndex((item: IFilterPlus) => item.key === "product");
    if (idx !== -1) {
      updatePageFilter((old: IFilterPlus[]) => {
        return [
          ...old.slice(0, idx),
          { key: "product", value: ids, name: productText },
          ...old.slice(idx + 1),
        ];
      });
    }
  };

  const handleSelectedSKUs = (ids: string[]) => {
    let skuText = "No SKUs";

    if (ids.length === SKUs?.length) {
      skuText = "All SKUs";
    } else if (ids.length === 0) {
      skuText = "No SKUs";
    } else if (ids.length === 1) {
      const item = SKUs?.find((item: DropdownItem) => item.id === ids[0]);
      skuText = item?.title || "";
    } else {
      skuText = `${ids.length} SKUs`;
    }

    pageUpdateFSKUs(ids);
    const idx = pageFilter.findIndex((item: IFilterPlus) => item.key === "sku");
    if (idx !== -1) {
      updatePageFilter((old: IFilterPlus[]) => {
        return [
          ...old.slice(0, idx),
          { key: "sku", value: ids, name: skuText },
          ...old.slice(idx + 1),
        ];
      });
    }
  };

  const handleSelectedVariants = (ids: string[]) => {
    const realVariants = variants.map((item: IVariantWrapper) => item.variants).flat();
    let variantText = "No Variants";

    if (ids.length === realVariants?.length) {
      variantText = "All Variants";
    } else if (ids.length === 0) {
      variantText = "No Variants";
    } else if (ids.length === 1) {
      const item = realVariants.find((item: IVariant) => item.id === ids[0]);
      variantText = item?.sku || item?.title || "";
    } else {
      variantText = `${ids.length} Variants`;
    }

    pageUpdateFVariants(ids);
    const idx = pageFilter.findIndex((item: IFilterPlus) => item.key === "variant");
    if (idx !== -1) {
      updatePageFilter((old: IFilterPlus[]) => {
        return [
          ...old.slice(0, idx),
          { key: "variant", value: ids, name: variantText },
          ...old.slice(idx + 1),
        ];
      });
    }
  };

  const handleClearFilter = (key: string) => {
    if (key === "product") {
      handleSelectedProducts([]);
      const currentProductFilter = pageFilter.find((filter) => filter.key === "product");
      if (!currentProductFilter?.value.includes("All Selected")) {
        handleClearFilter("variant");
        getVariants([], "");
      }
    } else if (key === "variant") {
      handleSelectedVariants([]);
    } else if (key === "category") {
      // TODO: clear products and variants?
      handleSelectedCategories([]);
    } else if (key === "sku") {
      // TODO: clear products and variants?
      handleSelectedSKUs([]);
    }

    if (pageFilter.length > 1) {
      updatePageFilter((old: IFilterPlus[]) => old.filter((item: IFilterPlus) => item.key !== key));
    }

    setCloseDropdown(true);
    onApply();
  };

  const handleClearFilters = () => {
    handleSelectedVariants([]);
    handleSelectedProducts([]);
    handleSelectedCategories([]);
    handleSelectedSKUs([]);

    updatePageFilter([{ key: "more", value: "Filter & Search", name: "Filter & Search" }]);

    onApply();
  };

  const getInitialSelection = (key: FilterKey) => {
    const defaultAllSelected = [
      "category",
      "product",
      "variant",
      "status",
      "inventoryStatus",
      "location",
      "brand",
      "supplier",
    ];

    if (defaultAllSelected.includes(key)) {
      return " - All Selected";
    } else if (FiltersWithoutDropdown.includes(key)) {
      return "";
    } else {
      return " - Nothing Selected";
    }
  };

  const handleSelectFilter = ({ key, value }: IObject) => {
    clearUnappliedFilters();
    if (FiltersWithoutDropdown.includes(key as FilterKey)) {
      setCloseDropdown(true);
    }

    updatePageFilter((old: IFilterPlus[]) => {
      const newFilter = [...old];
      newFilter.length -= 1;
      return [
        ...newFilter,
        {
          key,
          value: value + getInitialSelection(key as FilterKey),
          name: FiltersWithoutDropdown.includes(key as FilterKey) ? value : undefined,
        },
      ];
    });
  };

  const handleApply = () => {
    onApply();
    setCloseDropdown(true);
  };

  const clearUnappliedFilters = () => {
    pageFilter.forEach(({ key, name }) => {
      if (name === undefined) {
        updatePageFilter((old: IFilterPlus[]) =>
          old.filter((item: IFilterPlus) => item.key !== key)
        );
      }
    });
  };

  function getSelectedArray(key: string) {
    const value = pageFilter.find((item) => item.key === key)?.value;
    return Array.isArray(value) ? value : [];
  }

  const filterDropdowns: { [key: string]: ReactNode } = {
    more: (
      <FiltersDropdown onSelect={handleSelectFilter} filter={pageFilter} filterName={pageName} />
    ),
    category: (
      <SearchAndSelectDropdown
        data={categories}
        selected={getSelectedArray("category")}
        onApply={(ids) => {
          handleSelectedCategories(ids);
          setCloseDropdown(true);
          if (ids.length === 0) {
            handleClearFilter("category");
          }
        }}
        loading={categoriesLoading}
        disableApply={pageLoading === AsyncStatus.Loading}
        fetchData={getCategories}
      />
    ),
    sku: (
      <SearchAndSelectDropdown
        data={SKUs}
        selected={getSelectedArray("sku")}
        onApply={(ids) => {
          handleSelectedSKUs(ids);
          setCloseDropdown(true);
          if (ids.length === 0) {
            handleClearFilter("sku");
          }
        }}
        loading={AsyncStatus.Loaded}
        disableApply={pageLoading === AsyncStatus.Loading}
        fetchData={getSKUs}
      />
    ),
    product: (
      <SearchAndSelectDropdown
        data={products}
        selected={getSelectedArray("product")}
        onApply={(ids) => {
          if (pageFilter.find((filter) => filter.key === "variant")) {
            if (!confirm("This action will reset your variant filter, Do you want to continue?")) {
              return;
            }
          }
          handleSelectedProducts(ids);
          updateFetchVariants(true);
          setCloseDropdown(true);
          if (ids.length === 0) {
            handleClearFilter("product");
          }
        }}
        loading={productsLoading}
        disableApply={pageLoading === AsyncStatus.Loading}
        fetchData={getProducts}
      />
    ),
    variant: (
      <VariantsDropdown
        data={variants}
        variantKeyword={variantKeyword}
        setVariantKeyword={setVariantKeyword}
        selectedVariants={(() => {
          const variantValue = pageFilter.find((item) => item.key === "variant")?.value;
          return Array.isArray(variantValue) ? variantValue : [];
        })()}
        onApply={(ids) => {
          handleSelectedVariants(ids);
          updateFetchVariants(false);
          setCloseDropdown(true);
          if (ids.length === 0) {
            handleClearFilter("variant");
          }
        }}
        loading={variantsLoading}
        disableApply={pageLoading === AsyncStatus.Loading}
        getVariants={getVariants}
      />
    ),
    price: (
      <NumericRangeDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"price"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={
          pageFilter.find((item) => item.key === "price")?.name?.replace("price: ", "") || "=0"
        }
      />
    ),
    cost: (
      <NumericRangeDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"cost"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={
          pageFilter.find((item) => item.key === "cost")?.name?.replace("cost: ", "") || "=0"
        }
      />
    ),
    sales: (
      <NumericRangeDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"sales"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={
          pageFilter.find((item) => item.key === "sales")?.name?.replace("sales: ", "") || "=0"
        }
      />
    ),
    sale: (
      <RadioDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        options={[
          { key: "MTD", value: "Month to date" },
          { key: "QTD", value: "Quarter to date" },
          { key: "YTD", value: "Year to date" },
          { key: "All", value: "All" },
        ]}
        defaultOption={
          pageFilter.find((item) => item.key === "sale")?.name?.replace("Sales: ", "") ||
          "Month to date"
        }
        disableApply={pageLoading === AsyncStatus.Loading}
        type={"sale"}
      />
    ),
    noSale: (
      <RadioDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        options={[
          { key: "MTD", value: "Month to date" },
          { key: "QTD", value: "Quarter to date" },
          { key: "YTD", value: "Year to date" },
          { key: "All", value: "All" },
        ]}
        defaultOption={
          pageFilter.find((item) => item.key === "noSale")?.name?.replace("No Sales: ", "") ||
          "Month to date"
        }
        disableApply={pageLoading === AsyncStatus.Loading}
        type={"noSale"}
      />
    ),
    hasSales: (
      <RadioDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        options={[
          { key: "hasSales", value: "sales" },
          { key: "hasNoSales", value: "has no sales" },
        ]}
        defaultOption={"has sales"}
        disableApply={pageLoading === AsyncStatus.Loading}
        type={"hasSales"}
      />
    ),
    tag: (
      <TaggingDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"tag"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={(() => {
          const tag = pageFilter.find((item) => item.key === "tag")?.value;
          return Array.isArray(tag) ? tag.filter((status) => status !== "") : [];
        })()}
      />
    ),
    vendor: (
      <TaggingDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"vendor"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={(() => {
          const vendor = pageFilter.find((item) => item.key === "vendor")?.value;
          return Array.isArray(vendor) ? vendor.filter((status) => status !== "") : [];
        })()}
      />
    ),
    hasImage: (
      <RadioDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        options={[
          { key: "hasImage", value: "has image" },
          { key: "hasNoImage", value: "has no image" },
        ]}
        defaultOption={pageFilter.find((item) => item.key === "hasImage")?.name || "has image"}
        disableApply={pageLoading === AsyncStatus.Loading}
        type={"hasImage"}
      />
    ),
    hasDescription: (
      <RadioDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        options={[
          { key: "hasDescription", value: "has description" },
          { key: "hasNoDescription", value: "has no description" },
        ]}
        defaultOption={
          pageFilter.find((item) => item.key === "hasDescription")?.name || "has description"
        }
        disableApply={pageLoading === AsyncStatus.Loading}
        type={"hasDescription"}
      />
    ),
    status: (
      <MultiselectDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"status"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={(() => {
          const status = pageFilter.find((item) => item.key === "status")?.value;
          return Array.isArray(status)
            ? status.filter((status) => status !== "")
            : ["active", "draft", "archived"];
        })()}
      />
    ),
    weight: (
      <NumericRangeDropdown
        updatePageFilter={updatePageFilter}
        onApply={handleApply}
        type={"weight"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={
          pageFilter.find((item) => item.key === "weight")?.name?.replace("weight: ", "") || "=0"
        }
      />
    ),
    inventoryStatus: (
      <MultiselectDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"inventoryStatus"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={(() => {
          const inventoryStatus = pageFilter.find((item) => item.key === "inventoryStatus")?.value;
          return Array.isArray(inventoryStatus)
            ? inventoryStatus.filter((status) => status !== "")
            : ["Out of Stock", "Understock", "Healthy"];
        })()}
      />
    ),
    unitsOnHand: (
      <NumericRangeDropdown
        updatePageFilter={updatePageFilter}
        onApply={handleApply}
        type={"unitsOnHand"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={
          pageFilter
            .find((item) => item.key === "unitsOnHand")
            ?.name?.replace("unitsOnHand: ", "") || "=0"
        }
      />
    ),
    created: (
      <SingleSelectDropdown
        updatePageFilter={updatePageFilter}
        onApply={handleApply}
        type={"created"}
      />
    ),
    modified: (
      <SingleSelectDropdown
        updatePageFilter={updatePageFilter}
        onApply={handleApply}
        type={"modified"}
      />
    ),
    location: (
      <TaggingDropdown
        onApply={handleApply}
        updatePageFilter={updatePageFilter}
        type={"location"}
        disableApply={pageLoading === AsyncStatus.Loading}
        selected={(() => {
          const location = pageFilter.find((item) => item.key === "location")?.value;
          return Array.isArray(location) ? location.filter((status) => status !== "") : [];
        })()}
      />
    ),
  };

  return (
    <div className="flex flex-wrap items-center gap-1">
      {pageFilter.map(({ key, value, name }: IFilterPlus, idx: number) => (
        <Dropdown
          key={idx}
          name={`filter-${key}`}
          position={POSITION.BOTTOM_LEFT}
          overlay={filterDropdowns[key]}
          showArrow={key === "more" && name === "Filter & Search"}
          plusIcon
          closeDropdown={closeDropdown}
          setCloseDropdown={setCloseDropdown}
          className={"bg-purple-base pr-2.5 hover:bg-purple-base"}
          disabled={disabled || FiltersWithoutDropdown.includes(key as FilterKey)}
        >
          <button className="flex items-center overflow-hidden text-ellipsis whitespace-nowrap font-mono text-xs font-medium">
            {getLabel(key, value, name)}
            {!FILTER_PLACEHOLDERS.includes(name) && (
              <Clear
                className="cursor-pointer text-base-inverted "
                onClick={() => handleClearFilter(key)}
              />
            )}
          </button>
          {name === "Filter & Search" && pageFilter.length >= 2 ? (
            <PillCallout position="top-right" color="dark">
              {pageFilter.length - 1} added
            </PillCallout>
          ) : null}
        </Dropdown>
      ))}
      {pageFilter.length > 1 ||
      (pageFilter.length === 1 &&
        pageFilter[0].key !== "more" &&
        !FILTER_PLACEHOLDERS.includes(pageFilter[0].value)) ? (
        <Clear className="cursor-pointer text-base-text " onClick={handleClearFilters} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Filter;
