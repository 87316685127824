import { ResponsiveContainer, Bar, ComposedChart } from "recharts";
import { formatNumber, formatPercent } from "utils/helper-ts";

export const InventorySpark = ({ cell }: { cell: any }) => {
  let slope = -1.2 || cell.row.original.slope;
  let startingQuantity = 3154.11;
  let endingQuantity = 2506;
  let data =
    [
      {
        regression: 3154.11,
        // "quantitySold": 4000.11
      },
      {
        regression: 3082,
        // "quantitySold": 6000
      },
      {
        regression: 3010,
        // "quantitySold": 3000.11
      },
      {
        regression: 2938,
        // "quantitySold": 2800.11
      },
      {
        regression: 2866,
        // "quantitySold": 600.11
      },
      {
        regression: 2794,
        // "quantitySold": 4000.11
      },
      {
        // "regression": 2722,
        quantitySold: -3100.11,
      },
      {
        // "regression": 2650,
        quantitySold: -3500.11,
      },
      {
        // "regression": 2578,
        quantitySold: -2900.11,
      },
      {
        // "regression": 2650,
        quantitySold: -3500.11,
      },
      {
        // "regression": 2578,
        quantitySold: -2900.11,
      },
      {
        // "regression": 2506,
        quantitySold: -800,
      },
      {
        regression: 3154.11,
        // "quantitySold": 4000.11
      },
      {
        regression: 3082,
        // "quantitySold": 6000
      },
      {
        regression: 3010,
        // "quantitySold": 3000.11
      },
      {
        regression: 2938,
        // "quantitySold": 2800.11
      },
      {
        regression: 2866,
        // "quantitySold": 600.11
      },
      {
        regression: 2794,
        // "quantitySold": 4000.11
      },
    ] || cell.row.original.reChart;

  // console.log("Regression Spark", {
  //   slope,
  //   data
  // })

  return (
    <div style={{ width: "100%", height: 30 }} className="gap-2 h-full grid grid-cols-1">
      {/* <RatePoint quantity={startingQuantity} /> */}
      <div className="col-span-3 px-1">
        <ResponsiveContainer>
          <ComposedChart
            width={80}
            height={30}
            data={data}
            // barCategoryGap={1}
          >
            <Bar
              type="bumpX"
              dataKey="regression"
              fill={"green"}
              stackId={"a"}
              strokeWidth={1}
              radius={[2, 2, 2, 2]}
            />
            <Bar
              type="bumpX"
              dataKey="quantitySold"
              fill={"red"}
              stackId={"a"}
              strokeWidth={1}
              radius={[2, 2, 2, 2]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {/* <RatePoint quantity={endingQuantity} /> */}
      {/* <Slope slope={slope} /> */}
      {/* <ChangePill change={slope} decimal /> */}
    </div>
  );
};

const Slope = ({ slope }: { slope: number }) => {
  return (
    <p
      style={{ color: `${Math.sign(slope) === -1 ? "#b91c1c" : "#15803d"}` }}
      className="font-mono rounded-md bg-gray-50 text-2xs h-full w-full flex items-center justify-center"
    >
      {formatPercent(slope)}
    </p>
  );
};

const RatePoint = ({ quantity }: { quantity: number }) => {
  return (
    <p className="font-mono text-2xs h-full w-full flex items-center justify-center">
      {formatNumber(quantity, "rate", "d")}
    </p>
  );
};
