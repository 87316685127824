import React, { useState, useContext } from "react";
import { AppStateContext } from "context";
import type { FC, ReactNode } from "react";
import SideBar from "../components/SideBar";
import Mobile from "components/Others/desktop-only-warning";
import Loading from "components/Loading";
import { ChartLoadingWrapper } from "components/Loading";
import { AsyncStatus } from "types";

export interface BaseLayoutProps {
  children: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const [hideSidebar, setShowSidebar] = useState(true);
  const { initStatus } = useContext(AppStateContext);

  const handleClose = () => {
    setShowSidebar(!hideSidebar);
  };

  return (
    <div className="flex min-h-screen bg-base-inverted">
      <Mobile />
      {<SideBar collapsed={hideSidebar} close={handleClose} />}
      <div
        className={`${hideSidebar ? "ml-top-bar" : "ml-left-nav"}
          overflow-auto flex-1`}
      >
        <div className={`z-0 overflow-auto`}>{children}</div>
      </div>
    </div>
  );
};

export default BaseLayout;

export const ChartWrapper = ({
  noBorder,
  showChart,
  children,
  loading,
}: {
  noBorder?: boolean;
  showChart: boolean;
  children: React.ReactNode;
  loading: AsyncStatus;
}) => {
  if (showChart) {
    if (noBorder) {
      return <div className={``}>{children}</div>;
    }
    return <div className={`border-b border-border-internal bg-white rounded-md`}>{children}</div>;
  } else {
    return <>{null}</>;
  }
};
