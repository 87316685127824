import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { PillCallout } from "components/Pill";

import "react-datepicker/dist/react-datepicker.css";
import { compareDatesWithoutTime, forwardDays, minDate, rewindDays, daysBetween } from "utils/date";

let DatePickerStyle =
  "w-32 font-mono text-xs border border-solid border-border-internal rounded-full rounded hover:border-border-external transition-all focus:ring-0 focus:ring-transparent focus:ring-offset-transparent focus:border-border-hover ";

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (start: Date) => void;
  setEndDate: (end: Date) => void;
  allowFuture?: boolean;
  allowPast?: boolean;
  fixedDays?: number;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  allowFuture,
  allowPast = true,
  fixedDays,
}) => {
  const currentDate = new Date();
  const [openEndDate, setOpenEndDate] = useState(false);

  const customDayClassName = (day: any, minDate?: Date, maxDate?: Date) => {
    const hover = "hover:bg-purple-bright hover:text-white";
    if (
      compareDatesWithoutTime(startDate, day) === -1 &&
      compareDatesWithoutTime(day, endDate) === -1
    ) {
      return "bg-purple-background " + hover;
    } else if (
      compareDatesWithoutTime(startDate, day) === 0 ||
      compareDatesWithoutTime(endDate, day) === 0
    ) {
      return "bg-purple-bright text-white rounded " + hover;
    } else if (minDate && compareDatesWithoutTime(minDate, day) === 1) {
      return "text-gray-200 ";
    } else if (maxDate && compareDatesWithoutTime(maxDate, day) === -1) {
      if (allowFuture) {
        return "bg-white text-purple-bright";
      } else {
        return "text-gray-200 ";
      }
    } else if (!allowPast && compareDatesWithoutTime(currentDate, day) === 1) {
      return "text-gray-200 ";
    } else {
      return "bg-white text-black " + hover;
    }
  };

  const calculateMaxDate = () => {
    if (fixedDays) {
      if (allowPast) {
        return rewindDays(currentDate, fixedDays);
      } else {
        return undefined;
      }
    } else {
      return endDate;
    }
  };

  return (
    <div className="flex">
      <DatePicker
        selected={startDate}
        onSelect={() => {
          if (!fixedDays) {
            setOpenEndDate(true);
          }
        }}
        onChange={(date: Date) => {
          setStartDate(date);
          if (fixedDays) {
            setEndDate(forwardDays(date, fixedDays - 1));
          }
        }}
        className={DatePickerStyle}
        shouldCloseOnSelect
        dayClassName={(day: any) => {
          return customDayClassName(day, undefined, calculateMaxDate());
        }}
        minDate={allowPast ? null : currentDate}
        maxDate={calculateMaxDate()}
      />
      &nbsp;
      <div className="relative">
        <DatePicker
          onCalendarOpen={() => {
            setOpenEndDate(true);
          }}
          onCalendarClose={() => {
            setOpenEndDate(false);
          }}
          onClickOutside={() => {
            setOpenEndDate(false);
          }}
          onSelect={() => {
            setOpenEndDate(false);
          }}
          open={openEndDate}
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          className={DatePickerStyle}
          shouldCloseOnSelect
          dayClassName={(day: any) => {
            return customDayClassName(day, startDate, currentDate);
          }}
          minDate={startDate}
          maxDate={allowFuture ? null : currentDate}
          disabled={Boolean(fixedDays)}
        />
        <PillCallout color="dark" position="top-right">
          {daysBetween(startDate, endDate)} Days
        </PillCallout>
      </div>
    </div>
  );
};
