import { AppStateContext } from "context";
import React, { useContext } from "react";
import { UnitLoadingWrapper } from "components/Loading";
import { AsyncStatus, IOption } from "types";
import ReadMore from "components/Others/readMore";
import { Controller, UseFormSetValue } from "react-hook-form";
import Dropdown from "./Dropdown";
import { ReactComponent as Close } from "../../assets/images/Close-red.svg";

interface IInputCard {
  name: string;
  value: string;
  loading: AsyncStatus;
  inputName: string;
  control: any;
  setValue: UseFormSetValue<any>;
  validator: any;
  originalValue: string;
  errors: any;
  dotColor?: string;
  inventoryStatus?: string;
  onClick?: () => void;
  options?: IOption[];
  selected?: IOption;
  setSelected?: React.Dispatch<React.SetStateAction<IOption>>;
}

export const InputCard = ({
  name,
  value,
  loading,
  onClick,
  inputName,
  options,
  control,
  selected,
  setSelected,
  errors,
  setValue,
  validator,
  originalValue,
}: IInputCard) => {
  const handleSelect = (item: IOption) => {
    if (setSelected) {
      setSelected(item);
    }
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setValue(inputName, originalValue);
  };
  return (
    <div
      onClick={onClick}
      className={`${onClick ? "hover:bg-highlight-gentle hover:cursor-pointer transition-all" : ""}
      py-2 px-3`}
    >
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{name}</div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex w-full">
          <div className="text-black w-full">
            <Controller
              name={inputName}
              control={control}
              rules={{ validate: validator }}
              render={({ field }) => (
                <input
                  className="w-full h-full bg-white px-2 text-base-text justify-start items-center inline-flex font-mono rounded-md border border-gray-200 transition-all"
                  {...field}
                />
              )}
            />
          </div>
          {options && selected && (
            // <div className="text-white w-[230px]">
            <Dropdown options={options} option={selected} onSelect={handleSelect} />
            // </div>
          )}
          {value !== originalValue && (
            <button onClick={handleClear} className="ml-4">
              <Close />
            </button>
          )}
        </div>

        {/* @ts-ignore */}
        <div>{errors[inputName]?.message}</div>
      </div>
    </div>
  );
};

export const SimpleCard = ({
  title,
  children,
  loading,
}: {
  title: string;
  children: React.ReactNode;
  loading: AsyncStatus;
}) => {
  return (
    <div className={`transition-all py-2 px-3`}>
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{title}</div>
      </div>
      <div className="flex justify-between items-center">
        <CardRow>
          <UnitLoadingWrapper loading={loading}>
            <>{children}</>
          </UnitLoadingWrapper>
        </CardRow>
      </div>
    </div>
  );
};

const CardRow = ({ children }: { children: React.ReactNode }) => {
  return <div className="text-base-text text-xl font-semibold">{children}</div>;
};

export default InputCard;
