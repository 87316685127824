import React, { useState } from "react";
import { AsyncStatus, IDateOption, IFilterPlus, IOption, ITopPerfCriteria } from "types";
import {
  DATE_OPTIONS_FORECAST,
  DASHBOARD_TOP_CRITERIA_OPTIONS,
  DATE_OPTIONS,
  TIME_SERIES_OPTIONS,
  DATE_FREQUENCY_TIME_SERIES,
} from "utils/constants";
import ProductModal from "components/Modal/DetailsModal/productModal";

interface IInfoModalContext {
  showProductModal: boolean;
  updateShowProductModal: (showProductModal: boolean) => void;
  productModalProduct: string;
  updateProductModalProduct: (productModalProduct: string) => void;
  topPerfTime: IDateOption;
  updateTopPerfTime: (topPerfTime: IDateOption) => void;
  topPerfCriteria: ITopPerfCriteria;
  updateTopPerfCriteria: (topPerfCriteria: ITopPerfCriteria) => void;
  //time series
  timeSeriesTime: IDateOption;
  updatTimeSeriesTime: (KPITime: IDateOption) => void;
  inventoryTimeSeriesTime: IDateOption;
  updateInventoryTimeSeriesTime: (KPITime: IDateOption) => void;
  timeSeriesType: IOption;
  updateTimeSeriesType: React.Dispatch<React.SetStateAction<IOption>>;
  timeSeriesFrequency: IOption;
  updateTimeSeriesFrequency: React.Dispatch<React.SetStateAction<IOption>>;

  fOverrideRefCategories: string[];
  updateFOverrideRefCategories: (products: string[]) => void;
  fOverrideRefProducts: string[];
  updateFOverrideRefProducts: (products: string[]) => void;
  fOverrideRefVariants: string[];
  updateFOverrideRefVariants: (products: string[]) => void;
  loading: AsyncStatus;
  updateLoading: (loading: AsyncStatus) => void;
}

export const InfoModalContext = React.createContext<IInfoModalContext>({
  showProductModal: false,
  updateShowProductModal: () => {},
  productModalProduct: "",
  updateProductModalProduct: () => {},
  topPerfTime: DATE_OPTIONS[2],
  updateTopPerfTime: () => {},
  topPerfCriteria: DASHBOARD_TOP_CRITERIA_OPTIONS[0],
  updateTopPerfCriteria: () => {},
  //time series
  timeSeriesTime: DATE_OPTIONS_FORECAST[1],
  updatTimeSeriesTime: () => {},
  inventoryTimeSeriesTime: DATE_OPTIONS_FORECAST[2],
  updateInventoryTimeSeriesTime: () => {},
  timeSeriesType: TIME_SERIES_OPTIONS[0],
  updateTimeSeriesType: () => {},
  timeSeriesFrequency: DATE_FREQUENCY_TIME_SERIES[0],
  updateTimeSeriesFrequency: () => {},

  fOverrideRefCategories: [],
  updateFOverrideRefCategories: () => {},
  fOverrideRefProducts: [],
  updateFOverrideRefProducts: () => {},
  fOverrideRefVariants: [],
  updateFOverrideRefVariants: () => {},
  loading: AsyncStatus.Loading,
  updateLoading: () => {},
});

interface IInfoModalContextProps {
  children: React.ReactNode;
}

export const InfoModalProvider = (props: IInfoModalContextProps) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [productModalProduct, setProductModalProduct] = useState("");
  const [topPerfTime, setTopPerfTime] = useState(DATE_OPTIONS[2]);
  const [topPerfCriteria, setTopPerfCriteria] = useState(DASHBOARD_TOP_CRITERIA_OPTIONS[0]);
  //time series
  const [timeSeriesTime, setTimeSeriesTime] = useState(DATE_OPTIONS_FORECAST[1]);
  const [timeSeriesFrequency, setTimeSeriesFrequency] = useState(DATE_FREQUENCY_TIME_SERIES[0]);
  const [inventoryTimeSeriesTime, setInventoryTimeSeriesTime] = useState(DATE_OPTIONS_FORECAST[2]);
  const [timeSeriesType, setTimeSeriesType] = useState(TIME_SERIES_OPTIONS[0]);

  const [fOverrideRefCategories, setFOverrideRefCategories] = useState<string[]>([]);
  const [fOverrideRefProducts, setFOverrideRefProducts] = useState<string[]>([]);
  const [fOverrideRefVariants, setFOverrideRefVariants] = useState<string[]>([]);
  const [loading, setLoading] = useState(AsyncStatus.Loaded);

  return (
    <InfoModalContext.Provider
      value={{
        showProductModal,
        updateShowProductModal: setShowProductModal,
        productModalProduct,
        updateProductModalProduct: setProductModalProduct,
        topPerfTime,
        updateTopPerfTime: setTopPerfTime,
        topPerfCriteria,
        updateTopPerfCriteria: setTopPerfCriteria,
        timeSeriesTime,
        updatTimeSeriesTime: setTimeSeriesTime,
        inventoryTimeSeriesTime,
        updateInventoryTimeSeriesTime: setInventoryTimeSeriesTime,
        timeSeriesType,
        updateTimeSeriesType: setTimeSeriesType,
        timeSeriesFrequency,
        updateTimeSeriesFrequency: setTimeSeriesFrequency,

        fOverrideRefCategories,
        updateFOverrideRefCategories: setFOverrideRefCategories,
        fOverrideRefProducts,
        updateFOverrideRefProducts: setFOverrideRefProducts,
        fOverrideRefVariants,
        updateFOverrideRefVariants: setFOverrideRefVariants,
        loading,
        updateLoading: setLoading,
      }}
    >
      {props.children}
      <ProductModal
        close={() => {
          setShowProductModal(false);
        }}
        visible={showProductModal}
        product={productModalProduct}
      />
    </InfoModalContext.Provider>
  );
};
