import React from "react";
import { VerticalDirection } from "types";
import { ChangePill } from "components/Pill";
import { truncateClass } from "utils/helper-ts";
import { LineLegend, DashedLineLegend, AreaLegend } from "components/ChartLegend";
import { PillCallout } from "components/Pill";

export const BaseTooltip = ({
  title,
  subTitle,
  children,
  rawSubtitle,
  clickAction,
}: {
  title: string;
  children: React.ReactNode;
  subTitle?: string;
  rawSubtitle?: boolean;
  clickAction?: string;
}) => {
  return (
    <div className="m-10 relative	max-w-sm border border-solid border-border-hover shadow-popup rounded-xl bg-purple-base font-mono text-sm leading-5 text-white overflow-hidden">
      <div className="flex flex-col gap-2 px-4 py-4 bg-border-hover">
        <p className="font-bold ">{title}</p>
        {subTitle ? (
          <p className={`font-mono-bold ${rawSubtitle ? "" : "capitalize"}`}>{subTitle}</p>
        ) : null}
      </div>
      <div className="flex flex-col gap-2 px-4 py-4">{children}</div>
      {clickAction ? (
        <PillCallout inline>
          <p>{clickAction}</p>
        </PillCallout>
      ) : null}
    </div>
  );
};

export const BaseTooltipTwoCol = ({
  title,
  subTitle,
  children,
  rawSubtitle,
  extraWide,
}: {
  title: string;
  children: React.ReactNode;
  subTitle?: string;
  rawSubtitle?: boolean;
  extraWide?: boolean;
}) => {
  return (
    <div className="m-10 relativeborder border-solid border-border-hover shadow-popup rounded-xl bg-purple-base font-mono text-sm leading-5 text-white overflow-hidden">
      <div className="flex flex-col gap-2 px-4 py-4 bg-border-hover">
        <p className="font-bold ">{title}</p>
        {subTitle ? (
          <p className={`font-mono-bold ${rawSubtitle ? "" : "capitalize"}`}>{subTitle}</p>
        ) : null}
      </div>
      <div className="flex flex-col gap-2 px-4 py-4">{children}</div>
    </div>
  );
};

const tooltipItemStyle = "flex justify-between items-center w-full";

export const TooltipItem = ({
  metric,
  value,
  noTruncate,
}: {
  metric: string;
  value: string;
  noTruncate?: boolean;
}) => {
  return (
    <div className={tooltipItemStyle}>
      <p>{metric}</p>
      <Spacer />
      <p className={noTruncate ? "" : truncateClass}>{value}</p>
    </div>
  );
};

// INDENTED
export const TooltipSubItem = ({ metric, value }: { metric: string; value: string }) => {
  return (
    <div className={tooltipItemStyle + " pl-2"}>
      <p>{metric}</p>
      <Spacer />
      <p>{value}</p>
    </div>
  );
};

const Spacer = () => <div className="w-[50px] h-[1px]"></div>;

export const TooltipSubItemPill = ({
  // make this actually work
  metric,
  value,
  direction,
}: {
  metric: string;
  value: string;
  direction: VerticalDirection;
}) => {
  return (
    <div className="flex justify-between items-center w-full pl-2 ">
      <p>{metric}</p>
      <Spacer />
      <ChangePill change={parseFloat(value)} />
    </div>
  );
};

export const TooltipSubHeader = ({
  title,
  legendColor,
  dashed,
  line,
  area,
}: {
  title: string;
  legendColor?: string;
  dashed?: boolean;
  line?: boolean;
  area?: boolean;
}) => (
  <h3 className="flex items-center font-mono-bold">
    <span>{title}</span>
    {legendColor && dashed ? <DashedLineLegend lineColor={legendColor} /> : null}
    {legendColor && line ? <LineLegend lineColor={legendColor} /> : null}
    {legendColor && area ? <AreaLegend areaColor={legendColor} /> : null}
  </h3>
);
