import { useContext, useEffect, useState } from "react";
import { DATE_OPTIONS } from "utils/constants";
import { DashboardContext, AppStateContext } from "context";
import AreaTopBar from "../Dashboard/AreaTopBar";
import { MetricColumn, MetricColumnArea, MetricLoading } from "../Dashboard/MetricHelpers";
import { AsyncStatus, IDateOption } from "types";
import { formatNumber } from "utils/helper-ts";
import { IManualForecast, ISystemForecast } from "./ForecastOverrideModal";
import { ChangePill } from "components/Pill";
import { UnitLoadingWrapper } from "components/Loading";

export const CurrentForecast = ({
  system,
  current,
}: {
  system: ISystemForecast;
  current: IManualForecast;
}) => {
  const { user } = useContext(AppStateContext);
  const [loading, setLoading] = useState(AsyncStatus.Loaded);

  return (
    <MetricColumnArea>
      <AreaTopBar areaName="Current Forecast"></AreaTopBar>
      <MetricColumn>
        <div className="flex items-center">
          <Card
            name="Total Units"
            value={current.sum.toFixed(2)}
            loading={loading}
            change={((current.sum - system.sum) / system.sum) * 100}
          />
        </div>
        <Card
          name="Units Per Day"
          value={current.average.toFixed(2)}
          loading={loading}
          change={((current.average - system.average) / system.average) * 100}
        />
        <Card name="Days" value={system.days.toString()} loading={loading} />
      </MetricColumn>
    </MetricColumnArea>
  );
};

export default CurrentForecast;

const Card = ({ name, value, loading, onClick, change }: any) => {
  return (
    <div
      onClick={onClick}
      className={`${onClick ? "hover:bg-highlight-gentle hover:cursor-pointer transition-all" : ""}
      py-2 px-3`}
    >
      <div className="flex items-center">
        <div className="text-base-text-lighten text-md w-fit ">{name}</div>
      </div>

      <div className="flex justify-between items-center">
        <div className="text-base-text text-xl font-semibold">
          <UnitLoadingWrapper loading={loading}>
            <div className="flex">
              <div className="mr-2">{value}</div>
              {change ? <ChangePill change={change} /> : null}
            </div>
          </UnitLoadingWrapper>
        </div>
      </div>
    </div>
  );
};
