import { useMemo, useCallback, useState, useRef, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { blurBg } from "utils/helper-ts";
import useOutsideClick from "../../hooks/useOutsideClick";
import { shopNameOnly } from "utils/helper-ts";
import { capitalCase } from "change-case";
import { ReactComponent as Arrow } from "assets/images/back-arrow.svg";
import { useNavigate } from "react-router-dom";

const TopBar = ({
  collapsed,
  goBack,
  children,
  nameOverride
}: {
  collapsed: boolean;
  goBack?: string;
  children?: ReactNode;
  nameOverride?:string;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const alertsRef = useRef(null);
  const shop = localStorage.getItem("shop");

  const handleNotificationOpen = useCallback(() => {
    setNotificationOpen(true);
  }, []);

  const handleNotificationClose = useCallback(() => {
    setNotificationOpen(false);
  }, []);

  useOutsideClick(alertsRef, () => {
    handleNotificationClose();
  });

  return (
    <div
      style={blurBg}
      className={`z-20 relative flex items-center gap-3 text-sm font-mono text-base-text justify-start h-top-bar w-full border-b border-solid border-border-internal`}
    >
      <p className="mx-4 uppercase text-md font-mono-bold text-base-text tracking-wide flex items-center">
        {goBack && (
          <button
            onClick={() => {
              navigate(goBack);
            }}
          >
            <Arrow className="rounded-full mr-2" />
          </button>
        )}
        {nameOverride || capitalCase(pathname) || "Dashboard"}
      </p>
      {children ? children : null}
      <div className="text-base-navlight absolute right-0 top-0  p-1 text-[9px] opacity-90">
        {shop ? shopNameOnly(shop) : "No shop name"}
      </div>
    </div>
  );
};

export default TopBar;
