import React, { useContext } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  Table,
  Column,
  SortingState,
  PaginationState,
  Row,
  ColumnResizeMode,
} from "@tanstack/react-table";
import { insertRowSpan } from "components/Table";
import { IUser } from "context/types";
import "components/Table/index.css";

// will remove
import { rowStyler } from "./custom-row-map";
import { DemandHeaderCellMapper } from "./custom-header-map";
import { DemandCellMap } from "./custom-cell-map";
import { Pagination } from "components/Table";
import { DemandContext } from "context";
import TableScrollTop from "components/Others/TableScrollTop";
import TableScrollCurrent from "components/Others/TableScrollCurrent";
import { AsyncStatus } from "types";

export const STRICT_CELL_HEIGHT = 3.5; // trying in em

const DemandTable = ({
  user,
  rows,
  columns,
  sorting,
  updateSorting,
  pagination,
  setPagination,
  spannedRows = 1,
  pageCount,
  loading,
  editable,
  totalRowCount,
}: {
  user: IUser;
  rows: Row<any>[];
  columns: Column<any, unknown>[];
  sorting: SortingState;
  updateSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  spannedRows: number;
  pageCount: number;
  loading: AsyncStatus;
  editable: boolean;
  totalRowCount?: number;
}) => {
  const defaultData = React.useMemo(() => [], []);

  const [columnResizeMode, setColumnResizeMode] = React.useState<ColumnResizeMode>("onChange");

  const table = useReactTable({
    data: insertRowSpan(rows, spannedRows) ?? defaultData,
    columns: columns,
    columnResizeMode,
    pageCount: pageCount,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: updateSorting,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  // for table, looser context needs to be provided, this may even expand
  // for now the main goal is having currency, but there are other reasons
  // only question is whether this should be on the backend?
  interface GlobalTableProps {
    user: IUser;
  }

  let props: GlobalTableProps = {
    user,
  };

  return (
    <>
      <table
        className="overflow-scroll bg-base-background text-sm border-spacing-0 border-separate table-fixed "
        {...{
          style: {
            width: table.getCenterTotalSize(),
          },
        }}
      >
        <TableHeader table={table} currency={user.currency} loading={loading} editable={editable} />
        <TableBody
          table={table}
          currency={user.currency}
          spannedRows={spannedRows}
          loading={loading}
          editable={editable}
        />
      </table>
      <div className="absolute right-10 bottom-20 hover:pointer flex flex-col gap-2 shadow-lg">
        <TableScrollCurrent />
        <TableScrollTop />
      </div>
      <Pagination table={table} pagination={pagination} totalRowCount={totalRowCount} />
    </>
  );
};

const TableHeader = ({
  table,
  currency,
  loading,
  editable,
}: {
  table: Table<any>;
  currency: string;
  loading: AsyncStatus;
  editable: boolean;
}) => {
  return (
    <thead className="bg-base-background text-sm sticky top-0 z-10 shadow-sm ">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <DemandHeaderCellMapper
              loading={loading}
              key={header.id}
              header={header}
              render={flexRender(header.column.columnDef.header, header.getContext())}
              currency={currency}
              editable={editable}
            />
          ))}
        </tr>
      ))}
    </thead>
  );
};

const TableBody = ({
  table,
  currency,
  spannedRows,
  loading,
  editable,
}: {
  table: Table<any>;
  currency: string;
  spannedRows: number;
  loading: AsyncStatus;
  editable: boolean;
}) => {
  const { top, current } = useContext(DemandContext);

  return (
    <tbody>
      <tr style={{ height: 0, padding: 0 }}>
        <td style={{ height: 0, padding: 0 }}>
          <div style={{ height: 0, padding: 0 }} ref={top}></div>
        </td>
      </tr>
      {table.getRowModel().rows.map((row) => {
        return (
          <tr key={row.id} className={rowStyler(row.original.metric)}>
            {row.getVisibleCells().map((cell) => {
              return (
                <DemandCellMap
                  key={cell.id}
                  cell={cell}
                  currency={currency}
                  spannedRows={spannedRows}
                  loading={loading}
                  editable={editable}
                />
              );
            })}
            <td className={"bg-purple-background border-r border-b border-border-internal"}></td>
          </tr>
        );
      })}
      {/* this hack somehow works */}
      <tr style={{ height: 0, padding: 0 }}>
        <td style={{ height: 0, padding: 0 }}>
          <div style={{ height: 0, padding: 0 }} ref={current}></div>
        </td>
      </tr>
    </tbody>
  );
};

export default DemandTable;
