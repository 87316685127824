import { useEffect, useState, useContext } from "react";
import { LegendItem } from "components/ChartLegend";
import { AppStateContext, TopPerformersContext } from "context";
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { BaseTooltipTwoCol, TooltipSubHeader } from "components/ChartTooltip";
import client from "api";
import { HEX_COLORS, TW_COLORS } from "utils/chart";
import { STACKED_AREA_TAILWIND } from "utils/raw-colors";
import { formatCurrency, formatPercent, formatPercentWhole } from "utils/helper-ts";
import { ChartLoadingWrapper } from "components/Loading";
import Loading from "components/Loading";
import { AsyncStatus } from "types";

interface TopPerformersQuery {
  frequency: string;
  startDate: string;
  endDate: string;
  numberOfTopPerformers: number;
  metric: "revenue" | "shareOfRevenue";
  showOther: boolean;
}

interface LegendColor {
  tailwindBg: string;
  hex: string;
  productId: string;
}

function generateColorLegend(productRank: string[]): LegendColor[] {
  let legendColors = [];
  for (let i = 0; i < productRank.length; i++) {
    legendColors.push({
      tailwindBg: STACKED_AREA_TAILWIND[i].tailwindBg,
      hex: STACKED_AREA_TAILWIND[i].hex,
      productId: productRank[i],
    });
  }

  return legendColors;
}

const StackedAreaChart = ({
  frequency,
  startDate,
  endDate,
  numberOfTopPerformers,
  metric,
  showOther,
}: TopPerformersQuery) => {
  const { organizeBy } = useContext(TopPerformersContext);

  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [data, setData] = useState<any[]>([]);
  const [query, setQuery] = useState<TopPerformersQuery>({
    frequency,
    startDate,
    endDate,
    metric,
    numberOfTopPerformers: 10,
    showOther,
  });
  const [legendColors, setLegendColors] = useState<LegendColor[]>([]);
  const [others, setOthers] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const { user } = useContext(AppStateContext);

  async function fetchTopPerformers({
    frequency,
    startDate,
    endDate,
    numberOfTopPerformers,
    metric,
    showOther,
  }: TopPerformersQuery) {
    function removeDate(data: any) {
      let formatted: any = {};
      for (let k in data) {
        if (data[k].productTitle) {
          formatted[k] = data[k];
        }
      }
      return formatted;
    }

    function removeOthers(data: any) {
      let formatted: any = {};
      for (let k in data) {
        if (data[k].productTitle !== "others") {
          formatted[k] = data[k];
        }
      }
      return formatted;
    }

    try {
      setLoading(AsyncStatus.Loading);
      // we're capping at 10 for now
      numberOfTopPerformers = numberOfTopPerformers > 10 ? 10 : numberOfTopPerformers;

      setQuery({
        frequency,
        startDate,
        endDate,
        numberOfTopPerformers: 10,
        metric,
        showOther,
      });

      const res = await client.get(`/get-top-performers-demand-chart`, {
        params: {
          frequency,
          startDate,
          endDate,
          numberOfTopPerformers: 10,
          metric,
          showOther,
          organizeBy: organizeBy.key,
        },
      });

      let dateRemoved = removeDate(res.data.data[0]);

      // lets remove others
      setOthers(
        formatProducts(dateRemoved).filter((item: any) => item.productTitle === "others")[0]
      );
      let othersRemoved = removeOthers(dateRemoved);
      setProducts(formatProducts(othersRemoved));
      setLegendColors(generateColorLegend(Object.keys(othersRemoved)));
      setData(res.data.data);
      // console.log({
      //   data: res.data.data,
      //   formatProducts: formatProducts(dateRemoved),
      //   setOthers: formatProducts(dateRemoved).filter(
      //     (item: any) => item.productTitle === "others"
      //   )[0],
      //   othersRemoved: othersRemoved,
      //   setLegendColors: generateColorLegend(Object.keys(dateRemoved)),
      //   others: formatProducts(dateRemoved).filter((item: any) => item.productTitle === "others"),
      // });
      setLoading(AsyncStatus.Loaded);
    } catch (err: any) {
      setLoading(AsyncStatus.Failed);
      console.log(err);
    }
  }

  useEffect(() => {
    fetchTopPerformers({
      frequency,
      startDate,
      endDate,
      metric,
      numberOfTopPerformers,
      showOther,
    });
  }, [frequency, startDate, endDate, metric, numberOfTopPerformers, showOther, organizeBy]);

  return (
    <div style={{ width: "100%", height: 300 }} className="h-full p-4 pb-10">
      <ChartLoadingWrapper data={data} loading={loading}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            {...{
              overflow: "visible",
            }}
          >
            <XAxis
              dataKey="date"
              tickLine={false}
              style={{
                fontSize: 10,
                fontFamily: "SF Mono",
                fill: HEX_COLORS.currentYear,
                overflow: "visible",
              }}
              interval={data.length < 20 ? 0 : Math.floor(data.length / 20)}
              angle={-45}
              textAnchor="end"
            />
            {query?.metric === "revenue" ? (
              <YAxis
                tickLine={false}
                style={{
                  fontSize: 10,
                  fontFamily: "SF Mono",
                  fill: HEX_COLORS.currentYear,
                  height: 40,
                }}
                allowDecimals={false}
                padding={{ top: 10, bottom: 10 }}
                tickFormatter={(value) => formatCurrency(value, user.currency, true)}
              />
            ) : (
              <YAxis
                tickLine={false}
                style={{
                  fontSize: 10,
                  fontFamily: "SF Mono",
                  fill: HEX_COLORS.currentYear,
                  height: 40,
                }}
                allowDecimals={false}
                padding={{ top: 0, bottom: 0 }}
                domain={showOther ? [0, 100] : [0, "auto"]}
                tickFormatter={(value) => formatPercentWhole(value)}
              />
            )}
            <Tooltip
              wrapperStyle={{ zIndex: 50 }}
              active={true}
              content={<CustomizedTooltip metric={metric} showOthers={showOther} />}
              cursor={{ fill: "black" }}
            />
            {products.map((product: any, i: number) => {
              return (
                <Area
                  key={product.productTitle}
                  type="monotone"
                  dataKey={`[${product.rank}].value`}
                  stackId="1"
                  stroke={STACKED_AREA_TAILWIND[product.rank - 1].hex}
                  fill={STACKED_AREA_TAILWIND[product.rank - 1].hex}
                />
              );
            })}
            {showOther && others ? (
              <Area
                key={others.productTitle}
                type="monotone"
                dataKey={`[${others.rank}].value`}
                stackId="1"
                stroke={"black"}
                fill={"black"}
              />
            ) : null}
          </AreaChart>
        </ResponsiveContainer>
      </ChartLoadingWrapper>
    </div>
  );
};

export default StackedAreaChart;

function formatProducts(data: any) {
  let formatted = [];
  for (let k in data) {
    if (data[k].productTitle) {
      formatted.push({ ...data[k], rank: k });
    }
  }
  return formatted;
}

const CustomizedTooltip = ({ active, payload, currency, metric, showOthers }: any) => {
  function formatTooltipProducts(data: any) {
    let formatted = [];
    for (let k in data) {
      if (data[k].productTitle && data[k].productTitle !== "others") {
        formatted.push({ ...data[k], rank: k });
      }
    }
    return formatted;
  }

  function getOthers(data: any) {
    let others;
    for (let k in data) {
      if (data[k].productTitle == "others") {
        others = data[k];
      }
    }
    return others;
  }

  if (active && payload?.length) {
    const data: any = payload[0].payload;
    let others = getOthers(data);

    return (
      <BaseTooltipTwoCol title={data.date}>
        <>
          {showOthers ? (
            <>
              <TooltipSubHeader title={"Others"} line />
              <LegendItem
                title={
                  metric === "revenue"
                    ? formatCurrency(others.value, currency, false)
                    : formatPercent(others.value)
                }
                legendColor={"bg-black"}
                area
              />
            </>
          ) : null}
          {formatTooltipProducts(data).map((item: any, i: number) => {
            return (
              <div key={i}>
                <TooltipSubHeader title={item.rank + " " + item.productTitle} line />
                <LegendItem
                  title={
                    metric === "revenue"
                      ? formatCurrency(item.value, currency, false)
                      : formatPercent(item.value)
                  }
                  legendColor={STACKED_AREA_TAILWIND[i].tailwindBg}
                  area
                />
              </div>
            );
          })}
        </>
      </BaseTooltipTwoCol>
    );
  }
  return null;
};
