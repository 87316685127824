import { useState, useRef, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { ReactComponent as Angledown } from "../../assets/images/angledown-w.svg";
import { IOption } from "types";
import useOutsideClick from "hooks/useOutsideClick";

export const Dropdown = ({
  options,
  option,
  onSelect,
  disabled,
}: {
  options: IOption[];
  option: IOption;
  onSelect: ({ key, value }: IOption) => void;
  disabled?: boolean;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const childrenRef = useRef(null);

  useOutsideClick(childrenRef, () => {
    setDropdown(false);
  });

  const transitionClasses = {
    enter: "transform transition duration-[150ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[150ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  return (
    <div
      className="relative inline-block text-left font-mono rounded bg-violet-900 text-white mx-2 text-sm"
      ref={childrenRef}
    >
      <div>
        <button
          disabled={disabled}
          onClick={() => {
            !disabled && setDropdown(!dropdown);
          }}
          type="button"
          className={`inline-flex w-full justify-center items-center rounded-md px-2 py-1 
            ${disabled ? "opacity-50" : "hover:bg-violet-900"}
            transition-all
            focus:bg-violet-900 focus:outline-none focus:ring-2 focus:ring-[#4F369B] focus:ring-offset-2 focus:ring-offset-gray-100`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          <div className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
            {option.value}
          </div>
          <Angledown className={dropdown ? `rotate-180 transition-all` : `transition-all`} />
        </button>
      </div>
      <Transition show={dropdown} as={Fragment} {...transitionClasses}>
        <div
          className={`absolute right-0 z-40 mt-2 w-full origin-top-right rounded-md bg-violet-900 shadow-lg overflow-hidden focus:outline-none border border-border-internal max-h-96 overflow-y-auto`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          {options.map((item) => {
            return (
              <div
                key={item.key}
                className={`w-full text-xs flex items-center cursor-pointer hover:violet-900 h-6 px-2 transition-all ${
                  option.key === item.key ? " bg-gray-300/30 text-white font-extrabold" : ""
                }`}
                onClick={() => {
                  onSelect(item);
                  setDropdown(false);
                }}
              >
                {item.value}
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
};

export default Dropdown;
