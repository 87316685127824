import React, { useContext, useEffect, useState } from "react";
import {
  Bar,
  Cell,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Area,
  Line,
  Legend,
  Tooltip,
  Label,
} from "recharts";
import { HEX_COLORS, TW_COLORS } from "utils/chart";
import client from "api";
import BreadCrumb from "components/Breadcrumb";

import {
  BaseTooltip,
  TooltipSubItem,
  TooltipSubHeader,
  TooltipItem,
} from "components/ChartTooltip";
import { formatCurrency, formatNumber, errorHandler } from "utils/helper-ts";
import {
  AppStateContext,
  DashboardContext,
  InfoModalContext,
  InventoryContext,
  ModalContext,
} from "context";
import { ChartLegend, LegendItem } from "components/ChartLegend";
import { ChangePill } from "components/Pill";
import { ChartLoadingWrapper } from "components/Loading";
import { AsyncStatus } from "types";
import { daysBetween, currentDay, formatDate, toLocalDateString } from "utils/date";

interface ITooltip {
  active?: boolean;
  payload?: Array<any>;
  currency: string;
  currentDate: string;
  label?: string;
  currentPeriodData?: ChartPoint;
}

interface ChartPoint {
  accessorKey: string; // HISTORICAL, CURRENT, FORECAST
  name: string; // HISTORICAL, CURRENT, FORECAST
  date: string;
  productId: string;
  variantId: string;
  inventory: {
    quantityOnHand: number;
    isOutOfInventoryDate: boolean;
    demandLost: number;
    demandPtentiallyLost: number;
  };
  sale: {
    quantity: number;
    revenue: number;
  };
  quantityForecast: {
    expected: number;
    upper: number;
    lower: number;
  };
  revenueForecast: {
    expected?: number;
    upper: number;
    lower: number;
  };
}

const CustomizedTooltip = ({
  active,
  payload,
  currency,
  currentPeriodData,
  currentDate,
}: ITooltip) => {
  if (active && payload?.length) {
    const data: ChartPoint = payload[0].payload;

    if (data.name === "historical" || data.name === "current") {
      let inventoryOnHand = data.inventory.quantityOnHand,
        quantitySold = data.sale.quantity,
        revenue = data.sale.revenue,
        title = `${data.date} (${data.name})`;

      return (
        <BaseTooltip title={title}>
          <>
            {inventoryOnHand < 0 ? (
              <TooltipSubHeader title="Inventory" legendColor={"bg-red-500"} area />
            ) : (
              <TooltipSubHeader title="Inventory" legendColor={TW_COLORS.currentYearBg} area />
            )}
            {isNaN(inventoryOnHand) ? (
              <TooltipSubItem metric="No Inventory Data" value={""} />
            ) : (
              <TooltipSubItem metric="Inventory On Hand" value={formatNumber(inventoryOnHand)} />
            )}
            <TooltipSubHeader title="Demand" />
            <TooltipSubItem
              metric="Quantity Sold"
              value={quantitySold ? formatNumber(quantitySold) : "0"}
            />
            <TooltipSubItem metric="Revenue" value={formatCurrency(revenue, currency)} />
          </>
        </BaseTooltip>
      );
    } else {
      let inventoryOnHand = data.inventory.quantityOnHand,
        forecastedUnitSales = data.quantityForecast.expected,
        forecastedRevenue = data.revenueForecast.expected,
        oos = data.inventory.isOutOfInventoryDate,
        title = `${data.date} (${data.name})`;

      return (
        <BaseTooltip title={title}>
          <>
            {oos ? <TooltipSubHeader title="Out of Stock Date" /> : null}
            {inventoryOnHand < 0 ? (
              <TooltipSubHeader title="Inventory" legendColor={"bg-red-500"} area />
            ) : (
              <TooltipSubHeader title="Inventory" legendColor={TW_COLORS.currentYearBg} area />
            )}
            {isNaN(inventoryOnHand) ? (
              <TooltipSubItem metric="No Inventory Data" value={""} />
            ) : (
              <TooltipSubItem metric="Inventory On Hand" value={formatNumber(inventoryOnHand)} />
            )}
            <TooltipSubHeader title="Demand Forecast" />
            <TooltipSubItem
              metric="Forecasted Sales"
              value={forecastedUnitSales ? formatNumber(forecastedUnitSales) : "0"}
            />
            <TooltipSubItem
              metric="Forecasted Revenue"
              value={formatCurrency(forecastedRevenue, currency)}
            />
          </>
        </BaseTooltip>
      );
    }
  }
  return null;
};

interface VariantQuery {
  variantId: string;
}
interface ProductQuery {
  productId: string;
}
interface CategoryQuery {
  categoryTitle: string;
}

interface shopQuery {
  startDate: Date;
  endDate: Date;
  frequency: string;
}

type DemandChartQuery = VariantQuery | ProductQuery | CategoryQuery | shopQuery;

interface DemandChartQuerySelector {
  [key: string]: DemandChartQuery;
}

export const InventoryTimeseries = ({
  variantId,
  productId,
  showBreadcrumb,
  type,
  context,
  chartData,
  noBorder,
  loadingParent,
  horizontalLegend,
  understock,
  overstock,
}: {
  variantId: string;
  productId: string;
  variantName?: string;
  productName?: string;
  showBreadcrumb?: boolean;
  type: string;
  context?: React.Context<any>;
  chartData?: any;
  noBorder?: boolean;
  loadingParent?: AsyncStatus;
  horizontalLegend?: boolean;
  understock?: number;
  overstock?: number;
}) => {
  const { user } = useContext(AppStateContext);
  const { refreshTable, refreshChart, selectedRowData } = useContext(InventoryContext);
  const { OpenModal } = useContext(ModalContext);
  const [data, setData] = useState(chartData || []);
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [focusBar, setFocusBar] = useState(-1);
  // backend should probably send current date because of date issues
  // had to do this manipulation because backend doesn't send leading 0s on the date but js functions do
  const [currentDate, setCurrentDate] = useState(
    formatDate(new Date())
      .split("-")
      .map((sub: string) => sub.replace(/^0+/, ""))
      .join("-")
  );
  const [earliestArrivalDate, setEarliestArrivalDate] = useState("");
  // const [outOfStockDate, setOutOfStockDate] = useState("");
  const [outOfStockDate, setOutOfStockDate] = useState("");
  const [mouseLeave, setMouseLeave] = useState(true);
  const [currentPeriodData, setCurrentPeriodData] = useState();
  const currency = user.currency;
  const { inventoryTimeSeriesTime } = useContext(context || DashboardContext);

  useEffect(() => {
    // console.log({selectedRowData})
    if (variantId) {
      getNewInventoryData(productId, variantId);
    }
  }, [
    variantId,
    productId,
    currentDate,
    JSON.stringify(inventoryTimeSeriesTime),
    JSON.stringify(chartData),
    refreshTable,
    refreshChart,
  ]);

  useEffect(() => {
    if (typeof loadingParent !== "undefined") {
      setLoading(loadingParent);
    }
  }, [loadingParent]);

  const getNewInventoryData = async (productId: string, variantId: string) => {
    try {
      if (localStorage.getItem("shop") && productId) {
        setLoading(AsyncStatus.Loading);
        const res = await client.get(`/variant-inventory-hist-forecast`, {
          params: {
            startDate: inventoryTimeSeriesTime.startDate,
            endDate: inventoryTimeSeriesTime.endDate,
            productId,
            variantId,
          },
        });
        // console.log("inventory chart", {
        //   input: {
        //     path: `/variant-inventory-hist-forecast`,
        //     params: {
        //       startDate: inventoryTimeSeriesTime.startDate,
        //       endDate: inventoryTimeSeriesTime.endDate,
        //       productId,
        //       variantId
        //     }

        // },
        //   meta: res.data.data.metaData
        // })
        setData(res.data.data.respond);
        setOutOfStockDate(
          (res.data.data.metaData.oOSDate || "")
            .split("-")
            .map((sub: string) => sub.replace(/^0+/, ""))
            .join("-")
        );
        setEarliestArrivalDate(
          (res.data.data.metaData.earliestArrivalDate || "")
            .split("-")
            .map((sub: string) => sub.replace(/^0+/, ""))
            .join("-")
        );
        setLoading(AsyncStatus.Loaded);
      }
    } catch (err: any) {
      setLoading(AsyncStatus.Failed);
      console.error(err);
    }
  };

  const handleClickBreadCrumb = (id: string) => {
    if (id === "product") {
      OpenModal("product", undefined, undefined, productId);
    } else if (id === "variant") {
      OpenModal("variant", undefined, undefined, undefined, variantId);
    } else if (id === "category") {
      // maybe we'll do this someday
      OpenModal("category", undefined, "");
    }
  };

  return (
    <ChartLoadingWrapper data={data} loading={loading}>
      {showBreadcrumb && (
        <div className="absolute z-50 top-[70px] right-0 px-1 py-1 ">
          <BreadCrumb
            loading={loading}
            onClick={handleClickBreadCrumb}
            data={[
              {
                name: selectedRowData.productTitle,
                type: "product",
              },
              {
                name: selectedRowData.variantTitle,
                type: "variant",
              },
            ]}
          />
        </div>
      )}
      <ResponsiveContainer>
        <ComposedChart
          onMouseMove={(state) => {
            if (state.isTooltipActive && typeof state.activeTooltipIndex === "number") {
              setFocusBar(state.activeTooltipIndex);
              setMouseLeave(false);
            } else {
              setFocusBar(0);
              setMouseLeave(true);
            }
          }}
          data={data}
          margin={{ top: 16, right: 32, bottom: 8, left: 0 }}
          barCategoryGap={1}
          barSize={"100%"}
        >
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            style={{
              fontSize: 10,
              fontFamily: "SF Mono",
              fill: HEX_COLORS.currentYear,
            }}
            height={60}
            interval={data.length < 20 ? 0 : Math.floor(data.length / 20)}
            angle={-45}
            textAnchor="end"
          />

          <YAxis
            yAxisId={"left-axis"}
            tickLine={false}
            style={{
              fontSize: 10,
              fontFamily: "SF Mono",
              fill: HEX_COLORS.currentYear,
            }}
            allowDecimals={false}
            padding={{ top: 10, bottom: 2 }}
          >
            <Label
              position={"outside"}
              angle={-90}
              dx={-10}
              className="text-base-text font-mono text-xs"
            >
              Quantity
            </Label>
          </YAxis>
          <YAxis
            orientation="right"
            yAxisId={"right-axis"}
            tickLine={false}
            style={{
              fontSize: 10,
              fontFamily: "SF Mono",
              fill: HEX_COLORS.currentYear,
            }}
            allowDecimals={false}
            padding={{ top: 10, bottom: 10 }}
          >
            <Label
              position={"inside"}
              angle={-90}
              dx={10}
              className="text-base-text font-mono text-xs "
            >
              Revenue
            </Label>
          </YAxis>
          <Tooltip
            wrapperStyle={{ zIndex: 50 }}
            cursor={false}
            content={
              <CustomizedTooltip
                currentDate={currentDate}
                currency={currency}
                currentPeriodData={currentPeriodData}
              />
            }
          />
          <Legend
            content={
              <ChartLegend layout="horizontal">
                <LegendItem title="On Hand" legendColor={TW_COLORS.currentYearBg} area />
                <LegendItem
                  title="Lead Time"
                  legendColor={TW_COLORS.forecastConfidenceIntervalBg}
                  area
                />
                <LegendItem title="Lost Demand" legendColor={"bg-red-500"} area />
                <LegendItem
                  title="Potentially Lost Demand"
                  legendColor={"bg-base-text-lighten"}
                  area
                />
              </ChartLegend>
            }
          />
          <ReferenceLine
            x={currentDate}
            yAxisId="left-axis"
            stroke={HEX_COLORS.currentYear}
            strokeWidth={3}
            strokeDasharray={3}
          >
            <Label position="top" fontSize={11} dy={10} dx={25} color="red">
              today
            </Label>
          </ReferenceLine>
          {outOfStockDate !== "" ? (
            <ReferenceLine
              x={outOfStockDate}
              yAxisId="left-axis"
              stroke={"red"}
              strokeWidth={3}
              strokeDasharray={3}
            >
              <Label position="top" fontSize={11} dy={25} dx={45} color="red">
                Out of Stock
              </Label>
            </ReferenceLine>
          ) : null}
          <ReferenceArea
            x1={currentDate}
            x2={earliestArrivalDate}
            yAxisId="left-axis"
            fill={HEX_COLORS.forecastConfidenceInterval}
          ></ReferenceArea>
          <Bar
            yAxisId={"left-axis"}
            background={{ fill: "transparent" }}
            dataKey={(data) => {
              if (data.inventory.quantityOnHand && data.inventory.quantityOnHand >= 0) {
                return data.inventory.quantityOnHand;
              } else {
                return null;
              }
            }}
            stackId={"a"}
            radius={[4, 4, 4, 4]}
          >
            {(data || []).map((item: any, index: number) => {
              if (item.name === "historical") {
                if (item.inventory.isOutOfInventoryDate || item.inventory.quantityOnHand < 0) {
                  return <Cell key={item.date + index} fill={"red"} />;
                } else {
                  return <Cell key={item.date + index} fill={HEX_COLORS.currentYear} />;
                }
              } else {
                if (item.inventory.isOutOfInventoryDate || item.inventory.quantityOnHand < 0) {
                  return <Cell key={item.date + index} fill={"red"} />;
                } else {
                  return <Cell key={item.date + index} fill={HEX_COLORS.currentYear} />;
                }
              }
            })}
          </Bar>
          <Bar
            yAxisId={"left-axis"}
            background={{ fill: "transparent" }}
            dataKey={(data) => {
              if (data.inventory.lostDemand) {
                return data.inventory.lostDemand;
              } else {
                return 0;
              }
            }}
            fill="red"
            stackId={"a"}
            radius={[4, 4, 4, 4]}
          ></Bar>
          <Bar
            yAxisId={"left-axis"}
            background={{ fill: "transparent" }}
            dataKey={(data) => {
              if (data.inventory.demandPotentiallyLost) {
                return data.inventory.demandPotentiallyLost;
              } else {
                return 0;
              }
            }}
            fill={HEX_COLORS.currentYearLighten}
            // stroke="black"
            stackId={"a"}
            radius={[4, 4, 4, 4]}
          ></Bar>
          <Bar
            yAxisId={"left-axis"}
            background={{ fill: "transparent" }}
            dataKey={(data) => {
              if (data.quantityForecast.expected && data.inventory.demandLost > 0) {
                return data.inventory.demandLost;
              } else {
                return 0;
              }
            }}
            fill="red"
            stackId={"a"}
            radius={[4, 4, 4, 4]}
          ></Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </ChartLoadingWrapper>
  );
};

export default InventoryTimeseries;
