import React from "react";

export const AuthLink = ({
  link,
  text,
  tight,
}: {
  link: string;
  text: string;
  tight?: boolean;
}) => {
  const style = tight
    ? "font-mono-bold underline hover:bg-white/10 transition-all"
    : "font-mono-bold underline hover:bg-white/10 transition-all px-1";

  return (
    <span>
      <a href={link} target="_blank" rel="noreferrer" className={style}>
        {text}
      </a>
    </span>
  );
};

export const Link = ({ link, text, tight }: { link: string; text: string; tight?: boolean }) => {
  const style = tight
    ? "font-medium underline hover:bg-black/5 transition-all cursor-pointer rounded-md"
    : "font-medium underline hover:bg-black/5 transition-all px-1 cursor-pointer rounded-md";

  return (
    <span>
      <a href={link} target="_blank" rel="noreferrer" className={style}>
        {text}
      </a>
    </span>
  );
};

export const LinkLookalike = ({
  onClick,
  children,
  tight,
}: {
  onClick: () => void;
  children: React.ReactNode;
  tight?: boolean;
}) => {
  const style = tight
    ? "font-medium text-purple-base underline hover:bg-black/5 transition-all cursor-pointer rounded-md"
    : "font-medium text-purple-base underline hover:bg-black/5 transition-all px-1 cursor-pointer rounded-md";

  return (
    <span>
      <button onClick={onClick} className={style}>
        {children}
      </button>
    </span>
  );
};
