import React from "react";
import { openInNewTab } from "utils/helper-ts";

export const NoReturnData = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-base-inverted ">
      <div className="rounded-[10px] p-8 flex flex-col justify-center shadow-dashboard border border-solid ">
        <div className="flex items-center gap-3">
          <p className="font-mono text-2xl">🤔</p>
          <p className="font-mono-bold text-md">No data returned for given selection</p>
        </div>
        <div className="flex flex-col gap-3">
          {/* <p className="font-mono text-sm">You may not have any data for your selection. </p> */}
          <p className="font-mono text-sm">
            If this seems wrong, please let us know in the chat, or{" "}
            <a
              className="underline cursor-pointer transition-all hover:text-purple-base"
              onClick={() => {
                openInNewTab("https://calendly.com/bucephalus/30min");
              }}
            >
              schedule a call
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export const IncompleteOrMissingData = ({
  title,
  message,
  children,
}: {
  title?: string;
  message?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full ">
      <div className="rounded-[10px] p-8 flex gap-3 flex-col justify-center shadow-dashboard border border-solid bg-base-inverted">
        <div className="flex items-center gap-3">
          <p className="font-mono text-2xl">⚠️</p>
          <p className="font-mono-bold text-md">
            {`${title ? title : "Given Selection"} Missing Data`}
          </p>
        </div>
        <div className="flex flex-col gap-3">
          {/* <p className="font-mono text-sm">You may not have any data for your selection. </p> */}
          <p className="font-mono text-sm">
            <a
              className="underline cursor-pointer transition-all hover:text-purple-base"
              onClick={() => {
                openInNewTab("https://calendly.com/bucephalus/30min");
              }}
            >
              Schedule a call
            </a>
            &nbsp;so we can help you format your data.
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};
