import React, { useContext, useEffect, useState } from "react";
import { MetricModal } from "components/Modal/metricModal";
import InventoryHelpModal from "components/Modal/inventoryHelpModal";
import InventoryOnboardingModal from "components/Modal/inventoryModal";
import DemandForecastNotificationModal from "components/Modal/demandForecastNotification";
import ProductModal from "components/Modal/DetailsModal/productModal";
import VariantModal from "components/Modal/DetailsModal/variantModal";
import CategoryModal from "components/Modal/DetailsModal/categoryModal";
import VendorModal from "components/Modal/DetailsModal/vendorModal";
import SetPasswordModal from "components/Modal/setPasswordModal";
import OnboardingFailedModal from "components/Modal/onboardingFailedModal";
import UpdateLowStockModal from "components/Modal/DetailsModal/legacy/legacyLowStockUpdate";
import LocationModal from "components/Modal/DetailsModal/locationModal";
import { scrollLock } from "utils/helper";
import updateCellModal from "components/Modal/updateCellModal";
import { FakeStoreModal } from "components/Modal/fakeStoreModal";
import { ProgressModal } from "components/Modal/progressModal";
import { RequestContext } from "./RequestContext";
import { NoForecastModal } from "components/Modal/NoForecastModal";
import { useLocation } from "react-router-dom";
import ForecastOverrideModal from "components/Modal/ForecastOverrideModal";
import { IDateOption, IOption } from "types";
import {
  DATE_FREQUENCY_TIME_SERIES,
  PLANNING_DATE_OPTIONS,
  TIME_SERIES_OPTIONS,
} from "utils/constants";
import { DATE_OPTIONS, DATE_OPTIONS_FORECAST } from "utils/constants";
import { DemandContext } from "./Demand";
import { TopPerformersContext } from "./TopPerformers";
import { PlanningContext } from "./planning";
import { InfoModalContext } from "./InfoModal";
import { DashboardContext } from "./Dashboard";
import { InventoryContext } from "./Inventory";
import { NoRecommendationsModal } from "components/Modal/NoRecommendationsModal";

interface IModalContext {
  OpenModal: (
    modalName: string,
    title?: string,
    category?: string,
    product?: string,
    variant?: string,
    location?: string,
    //for edit low stock
    productTitle?: string,
    variantTitle?: string,
    value?: string,
    type?: string,
    vendor?: string
  ) => void;
  forecastOverrideDateRange: IDateOption;
  updateForecastOverrideDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;
  timeSeriesType: IOption;
  updateTimeSeriesType: React.Dispatch<React.SetStateAction<IOption>>;
  timeSeriesFrequency: IOption;
  updateTimeSeriesFrequency: React.Dispatch<React.SetStateAction<IOption>>;
  onlyPastDateRange: IDateOption;
  updateOnlyPastDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;
}

export const ModalContext = React.createContext<IModalContext>({
  OpenModal: () => {},
  forecastOverrideDateRange: PLANNING_DATE_OPTIONS[3],
  updateForecastOverrideDateRange: () => {},
  timeSeriesType: TIME_SERIES_OPTIONS[0],
  updateTimeSeriesType: () => {},
  timeSeriesFrequency: DATE_FREQUENCY_TIME_SERIES[0],
  updateTimeSeriesFrequency: () => {},
  onlyPastDateRange: DATE_OPTIONS[3],
  updateOnlyPastDateRange: () => {},
});

export interface ModalContextProps {
  children: React.ReactNode;
}

export const modalMapping: { [key: string]: any } = {
  category: CategoryModal,
  vendor: VendorModal,
  product: ProductModal,
  variant: VariantModal,
  location: LocationModal,
  metric: MetricModal,
  InventoryHelp: InventoryHelpModal,
  inventoryOnboarding: InventoryOnboardingModal,
  demandForecastNotification: DemandForecastNotificationModal,
  setPassword: SetPasswordModal,
  onboardingFailed: OnboardingFailedModal,
  updateLowStock: UpdateLowStockModal,
  updateCellModal: updateCellModal,
  fakeStoreModal: FakeStoreModal,
  noForecastModal: NoForecastModal,
  forecastOverrideModal: ForecastOverrideModal,
  noRecommendationsModal: NoRecommendationsModal,
};

export const ModalProvider = (props: ModalContextProps) => {
  const { timeSeriesTime, updatTimeSeriesTime, updateTopPerfTime, updateInventoryTimeSeriesTime } =
    useContext(InfoModalContext);
  const { topPerfTime } = useContext(DashboardContext);
  const { dateRange: demandDateRange } = useContext(DemandContext);
  const { updateRefreshTable: updateRefreshInventory } = useContext(InventoryContext);
  const { dateRange: topPerformersDateRange } = useContext(TopPerformersContext);
  const { dateRange: planningDateRange, updateRefreshTable: updateRefreshPlanning } =
    useContext(PlanningContext);

  const page = useLocation();
  const [currentState, setCurrentState] = useState("metric");
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [product, setProduct] = useState("");
  const [variant, setVariant] = useState("");
  const [location, setLocation] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [variantTitle, setVariantTitle] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("low");
  const [vendor, setVendor] = useState("");
  const [edited, setEdited] = useState(false);
  const [forecastOverrideDateRange, setForecastOverrideDateRange] = useState(
    PLANNING_DATE_OPTIONS[3]
  );
  const [timeSeriesType, setTimeSeriesType] = useState(TIME_SERIES_OPTIONS[1]);
  const [timeSeriesFrequency, setTimeSeriesFrequency] = useState(DATE_FREQUENCY_TIME_SERIES[0]);

  const [dateRange, setDateRange] = useState(DATE_OPTIONS_FORECAST[2]);
  const [onlyPastDateRange, setOnlyPastDateRange] = useState(DATE_OPTIONS[3]);

  const OpenModal = (
    modalName: string,
    title = "",
    category = "",
    product = "",
    variant = "",
    location = "",
    productTitle = "",
    variantTitle = "",
    value = "",
    type = "",
    vendor = ""
  ) => {
    setCurrentState(modalName);
    setTitle(title);
    setCategory(category);
    setProduct(product);
    setVariant(variant);
    setLocation(location);
    setProductTitle(productTitle);
    setVariantTitle(variantTitle);
    setValue(value);
    setType(type);
    setVisible(true);
    scrollLock(true);
    setVendor(vendor);
  };

  const handleClose = () => {
    scrollLock(false);
    setVisible(false);
    if (page.pathname === "/purchase-planning" && edited) {
      updateRefreshPlanning((prev) => !prev);
      setEdited(false);
    } else if (page.pathname === "/inventory" && edited) {
      updateRefreshInventory((prev) => !prev);
      setEdited(false);
    }
  };

  useEffect(() => {
    handleClose();
    switch (page.pathname) {
      case "/top-performers":
        setDateRange(topPerformersDateRange);
        break;
      case "/demand":
        setDateRange(demandDateRange);
        break;
      case "/purchase-planning":
        setDateRange(planningDateRange);
        break;
      case "/dashboard":
        setDateRange(topPerfTime);
        break;
      default:
        break;
    }
  }, [page, topPerformersDateRange, demandDateRange, planningDateRange, topPerfTime]);

  useEffect(() => {
    if (dateRange.key !== "custom") {
      updatTimeSeriesTime(DATE_OPTIONS_FORECAST.find((item) => item.key === dateRange.key)!);
      updateInventoryTimeSeriesTime(
        DATE_OPTIONS_FORECAST.find((item) => item.key === dateRange.key)!
      );

      const newDateRange = {
        ...dateRange,
        name: DATE_OPTIONS.find((item) => item.key === dateRange.key)?.name || "error",
      };
      /*       const newDateRange = {
        ...dateRange,
        name: DATE_OPTIONS.find((item) => item.key === dateRange.key)?.name || "error",
        endDate: dateRange.endDate > new Date() ? new Date() : dateRange.endDate,
      }; */
      setOnlyPastDateRange(newDateRange);

      updateTopPerfTime(newDateRange);
    } else {
      updatTimeSeriesTime(dateRange);
      updateInventoryTimeSeriesTime(dateRange);
    }
  }, [dateRange, visible]);

  const CurrentModal: React.FC = () => {
    const Modal = modalMapping[currentState];
    return (
      <Modal
        visible={visible}
        close={handleClose}
        title={title}
        handleOk={handleClose}
        handleCancel={handleClose}
        category={category}
        product={product}
        variant={variant}
        location={location}
        productTitle={productTitle}
        variantTitle={variantTitle}
        value={value}
        sectionTitle={title}
        type={type}
        dateRange={dateRange}
        vendor={vendor}
        setEdited={setEdited}
      />
    );
  };

  return (
    <ModalContext.Provider
      value={{
        OpenModal,
        forecastOverrideDateRange,
        updateForecastOverrideDateRange: setForecastOverrideDateRange,
        timeSeriesType,
        updateTimeSeriesType: setTimeSeriesType,
        timeSeriesFrequency,
        updateTimeSeriesFrequency: setTimeSeriesFrequency,
        onlyPastDateRange,
        updateOnlyPastDateRange: setOnlyPastDateRange,
      }}
    >
      {props.children}
      <CurrentModal />
      <ProgressModal />
    </ModalContext.Provider>
  );
};
