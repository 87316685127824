import React, { useContext, useEffect, useState } from "react";
import {
  DATE_OPTIONS,
  DATE_OPTIONS_FORECAST,
  FORECAST_OVERRIDE_BASE_DATA_OPTIONS,
  FORECAST_OVERRIDE_REFERENCE_OPTIONS,
  FORECAST_OVERRIDE_SELECTION_OPTIONS,
} from "utils/constants";
import { DashboardContext, AppStateContext, PlanningContext } from "context";
import { Card, SimpleCard } from "../Dashboard/Card";
import AreaTopBar from "../Dashboard/AreaTopBar";
import { MetricColumn, MetricColumnArea, MetricLoading } from "../Dashboard/MetricHelpers";
import { AsyncStatus, IDateOption, IFilterPlus, IOption } from "types";
import { formatNumber } from "utils/helper-ts";
import DateRangeDropdown from "components/DropdownDateRange";
import Filter from "components/Filter";
import Dropdown from "components/Dashboard/Dropdown";

export const OverrideReference = ({
  baseData,
  setBaseData,
  modifierType,
  setModifierType,
  referenceTimeFrame,
  setReferenceTimeFrame,
  overrideRefFilter,
  updateOverrideRefFilter,
  variantReference,
  setVariantReference,
  disabled,
  hasManualForecast,
  fixedDays,
  setShowApply,
  children,
}: {
  baseData: IOption;
  setBaseData: React.Dispatch<React.SetStateAction<IOption>>;
  modifierType: IOption;
  setModifierType: React.Dispatch<React.SetStateAction<IOption>>;
  referenceTimeFrame: IDateOption;
  setReferenceTimeFrame: React.Dispatch<React.SetStateAction<IDateOption>>;
  overrideRefFilter?: IFilterPlus[];
  updateOverrideRefFilter?: any;
  variantReference: IOption;
  setVariantReference: React.Dispatch<React.SetStateAction<IOption>>;
  disabled: boolean;
  hasManualForecast: boolean;
  fixedDays: number;
  setShowApply: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(AsyncStatus.Loaded);

  return (
    <MetricColumnArea>
      <AreaTopBar areaName="Place Forecast Override"></AreaTopBar>
      {children}
      <div className={disabled ? "opacity-50" : ""}>
        <div className="bg-base-lightwhite shadow-dashboard w-full rounded-[10px] border border-solid border-border-internal ">
          <SimpleCard title="Select Base Data" loading={loading}>
            <Dropdown
              options={
                hasManualForecast
                  ? FORECAST_OVERRIDE_BASE_DATA_OPTIONS
                  : FORECAST_OVERRIDE_BASE_DATA_OPTIONS.slice(0, 2)
              }
              option={baseData}
              onSelect={(item: IOption) => {
                setBaseData(item);
                setShowApply(true);
              }}
              disabled={disabled}
            />
          </SimpleCard>
          <SimpleCard title="Reference Time Frame" loading={loading}>
            <DateRangeDropdown
              options={DATE_OPTIONS.slice(-1)}
              option={referenceTimeFrame}
              onSelect={(selection: IDateOption) => {
                setReferenceTimeFrame(selection as IDateOption);
                setShowApply(true);
              }}
              fixedDays={fixedDays}
              allowPast={baseData.key === "ownHistSales"}
              allowFuture={baseData.key !== "ownHistSales"}
              disabled={baseData.key !== "ownHistSales"}
            />
          </SimpleCard>
          <SimpleCard title="Select Reference Variant" loading={loading}>
            <div className="flex gap-2">
              <Dropdown
                options={FORECAST_OVERRIDE_SELECTION_OPTIONS}
                option={variantReference}
                onSelect={(item: IOption) => {
                  setVariantReference(item);
                  setShowApply(true);
                }}
                disabled={baseData.key !== "ownHistSales"}
              />
              {variantReference.key === "other" && (
                <Filter
                  onApply={() => {
                    setShowApply(true);
                  }}
                  pageName="overrideReference"
                  overrideRefFilter={overrideRefFilter}
                  updateOverrideRefFilter={updateOverrideRefFilter}
                  // disabled={variantReference.key !== "other"}
                />
              )}
            </div>
          </SimpleCard>
          <SimpleCard title="Reference Modifier Type" loading={loading}>
            <Dropdown
              options={FORECAST_OVERRIDE_REFERENCE_OPTIONS}
              option={modifierType}
              onSelect={(item: IOption) => {
                setModifierType(item);
                setShowApply(true);
              }}
              disabled={variantReference.key !== "other"}
            />
          </SimpleCard>
        </div>
      </div>
    </MetricColumnArea>
  );
};

export default OverrideReference;
