import React, { ChangeEvent, useContext, useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  Row,
  Table,
  ColumnResizeMode,
  getExpandedRowModel,
  PaginationState,
} from "@tanstack/react-table";
import { brandGradient } from "utils/helper-ts";
import TableScrollTop from "components/Others/TableScrollTop";
import { Pagination } from "components/Table";
import { AppStateContext, PlanningContext, InventoryContext } from "context";
import { AsyncStatus } from "types";
import { NoReturnData } from "components/Dashboard/DataWarning";
import { FailedFetch } from "./FailedFetch";
import ChooseColumns from "./ChooseColumns";
export const STRICT_CELL_HEIGHT = 3.5; // trying in em

export type IRow = {
  title: string;
  units: string;
  subRows?: IRow[];
};

const BaseTable = ({
  name,
  rows,
  columns,
  sorting,
  updateSorting,
  pagination,
  setPagination,
  onAllSelectedChange,
  selectedRows,
  onSelectedRowsChange,
  pageCount,
  loading,
  fullWidth,
  HeaderMapperComponent,
  CellMapperComponent,
  minColSize,
  setRows,
  editAlert,
  totalRowCount,
  pageContext,
}: {
  name: string;
  rows: Row<any>[];
  columns: any[];
  sorting: SortingState;
  updateSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  onAllSelectedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: Boolean;
  selectedRows?: Record<string, boolean>;
  onSelectedRowsChange?: ({ sRows }: { sRows: Record<string, boolean> }) => void;
  pageCount: number;
  loading: AsyncStatus;

  HeaderMapperComponent: any;
  CellMapperComponent: any;
  minColSize: number;
  setRows?: React.Dispatch<React.SetStateAction<any[]>>;
  editAlert?: (alert: any) => void;
  totalRowCount?: number;
  pageContext?: React.Context<any>;
}) => {
  const { user } = useContext(AppStateContext);
  const [columnResizeMode, setColumnResizeMode] = React.useState<ColumnResizeMode>("onChange");
  const [rowSelection, setRowSelection] = React.useState(selectedRows || {});

  const table = useReactTable({
    data: rows,
    columns: columns as any,
    columnResizeMode,
    pageCount: pageCount,
    defaultColumn: {
      minSize: minColSize,
      maxSize: 300,
    },
    state: {
      sorting,
      rowSelection,
      pagination,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: updateSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange({ sRows: rowSelection });
    }
  }, [rowSelection]);
  useEffect(() => {
    setRowSelection(selectedRows || {});
  }, [selectedRows]);

  switch (loading) {
    case AsyncStatus.Empty:
      return <NoReturnData />;
    case AsyncStatus.Failed:
      return <FailedFetch />;
    default:
      return (
        <>
          <table
            className="overflow-scroll bg-base-background text-sm border-spacing-0 border-separate table-fixed w-full"
            {...{
              style: {
                width: fullWidth ? "" : table.getCenterTotalSize(),
              },
            }}
          >
            <TableHeader
              table={table}
              onAllSelectedChange={onAllSelectedChange}
              loading={loading}
              currency={user.currency}
              HeaderMapperComponent={HeaderMapperComponent}
              name={name}
            />
            <TableBody
              pageContext={pageContext}
              table={table}
              loading={loading}
              currency={user.currency}
              CellMapperComponent={CellMapperComponent}
              setRows={setRows}
              editAlert={editAlert}
              rows={rows}
            />
          </table>
          <div className="absolute right-10 bottom-20 hover:pointer">
            <TableScrollTop />
          </div>
          <Pagination table={table} pagination={pagination} totalRowCount={totalRowCount} />
          <div className="absolute right-10 bottom-20 hover:pointer">
            <TableScrollTop />
          </div>
        </>
      );
  }
};

const TableHeader = ({
  table,
  onAllSelectedChange,
  currency,
  loading,
  HeaderMapperComponent,
  name,
}: {
  table: Table<any>;
  onAllSelectedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  currency: string;
  loading: AsyncStatus;
  HeaderMapperComponent: any;
  name: string;
}) => {
  return (
    <thead className="bg-base-background text-sm sticky top-0 z-10 shadow-sm ">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <HeaderMapperComponent
              table={table}
              loading={loading}
              key={header.id}
              header={header}
              render={flexRender(header.column.columnDef.header, header.getContext())}
              currency={currency}
              onAllSelectedChange={onAllSelectedChange}
            />
          ))}

          <th
            className={`bg-purple-background border-x border-b border-border-internal sticky right-0 ${
              headerGroup.depth === 0 && "z-[1001]"
            }`}
            style={{ width: 20 }}
          >
            {headerGroup.depth === 0 && <ChooseColumns table={table} name={name} />}
          </th>
        </tr>
      ))}
    </thead>
  );
};

const TableBody = ({
  table,
  currency,
  loading,
  CellMapperComponent,
  setRows,
  editAlert,
  pageContext,
  rows,
}: {
  table: Table<any>;
  currency: string;
  loading: AsyncStatus;
  CellMapperComponent: any;
  setRows?: React.Dispatch<React.SetStateAction<any[]>>;
  editAlert?: (alert: any) => void;
  pageContext?: React.Context<any>;
  rows?: any;
}) => {
  const { selectedVariantId } = useContext(pageContext || PlanningContext);
  const { selectedRow, updateSelectedRow } = useContext(AppStateContext);

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => {
        return (
          <tr
            key={row.id}
            className={
              selectedRow === row.id ||
              (selectedVariantId && selectedVariantId === row.original.variantId)
                ? "bg-gray-100"
                : // ? "transition-all bg-gradient-to-r from-highlight-gentle to-transparent "
                  "hover:bg-gray-50"
            }
            // style={(
            //   selectedRow=== row.id ||
            //   (selectedVariantId && selectedVariantId === row.original.variantId)
            //   ? brandGradient
            //   : {backgroundColor:""}
            // )}
            onClick={() => {
              updateSelectedRow(row.id);
            }}
          >
            {row.getVisibleCells().map((cell) => {
              return (
                <CellMapperComponent
                  key={cell.id}
                  cell={cell}
                  currency={currency}
                  loading={loading}
                  setData={setRows}
                  editAlert={editAlert}
                  data={rows}
                />
              );
            })}
            <td
              className={
                "bg-purple-background border-x border-b border-border-internal sticky right-0"
              }
            ></td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default BaseTable;
