import React from "react";

//
interface ILegend {
  payload?: Array<{
    value: string;
    dataKey: string;
    type: string;
    color: string;
  }>;
}

export const ChartLegend = ({
  children,
  payload,
  forcedWidth,
  layout = "horizontal",
}: {
  children: React.ReactNode;
  forcedWidth?: number;
  payload?: ILegend;
  layout?: "horizontal" | "vertical";
}) => {
  if (layout === "horizontal") {
    return (
      <div
        style={{ width: forcedWidth ? forcedWidth : "" }}
        className="flex justify-center text-xs gap-3 mb-2"
      >
        {children}
      </div>
    );
  } else {
    return (
      <div
        style={{ width: forcedWidth ? forcedWidth : "" }}
        className="flex flex-col justify-center text-xs gap-3 m-2 mt-10 p-2"
      >
        {children}
      </div>
    );
  }
};

export const LegendItem = ({
  title,
  legendColor,
  dashed,
  line,
  area,
  dot,
  square
}: {
  title: string;
  legendColor?: string;
  dashed?: boolean;
  line?: boolean;
  area?: boolean;
  dot?: boolean;
  square?: boolean;
}) => (
  <h3 className="flex items-center px-4 p-1 rounded-full truncate font-mono">
    <p>{title}</p>
    {legendColor && dashed ? <DashedLineLegend lineColor={legendColor} /> : null}
    {legendColor && line ? <LineLegend lineColor={legendColor} /> : null}
    {legendColor && area ? <AreaLegend areaColor={legendColor} /> : null}
    {legendColor && dot ? <DotLegend areaColor={legendColor} /> : null}
    {legendColor && square ? <SquareLegend areaColor={legendColor} /> : null}
  </h3>
);

export const LineLegend = ({ lineColor }: { lineColor: string }) => {
  return <span className={`${lineColor} h-[3px] w-6 ml-1.5 rounded-sm`} />;
};

export const DashedLineLegend = ({ lineColor }: { lineColor: string }) => {
  return (
    <div className="flex gap-1 ml-1.5">
      <span className={`${lineColor} h-[3px] w-1.5 rounded-sm`}></span>
      <span className={`${lineColor} h-[3px] w-1.5 rounded-sm`}></span>
      <span className={`${lineColor} h-[3px] w-1.5 rounded-sm`}></span>
    </div>
  );
};

export const AreaLegend = ({ areaColor }: { areaColor: string }) => {
  return <span className={`${areaColor} h-[9px] w-6 ml-1.5 rounded-sm`} />;
};

export const DotLegend = ({ areaColor }: { areaColor: string }) => {
  return <span className={`${areaColor} p-1.5 ml-1.5 rounded-full ring-2`} />;
};

export const SquareLegend = ({ areaColor }: { areaColor: string }) => {
  return <span className={`${areaColor} p-1.5 ml-1.5 rounded-sm ring-2`} />;
};
